/* eslint-disable */
import { GraphQLResult } from '@aws-amplify/api'
import {
  getInvestmentsByUser,
  getPaymentsByInvestment,
  GetSigningUrl,
} from 'graphql/queries'
import {
  createInvestment,
  deleteInvestment,
  updateInvestment,
} from 'graphql/mutations'
import {
  AgreementTypes,
  CreateInvestmentMutation,
  DeleteInvestmentMutation,
  GetInvestmentsByUserQuery,
  GetPaymentsByInvestmentQuery,
  GetSigningUrlQuery,
  UpdateInvestmentMutation,
} from 'API'
import { API } from 'aws-amplify'
import {
  getDaysLeftToFund,
  noUserHelper,
  stringToNumberStr,
} from 'utils/helpers'

export const getInvestmentData = async (userID: string) => {
  try {
    const investmentData = (await API.graphql({
      query: getInvestmentsByUser,
      variables: {
        userID: userID,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<GetInvestmentsByUserQuery>

    const expiredInvestments: Array<any> = [] // investments with zero days left to funded
    const validInvestments: Array<any> = []

    investmentData.data?.getInvestmentsByUser?.items.forEach((x: any) => {
      const daysLeftToFund_ = getDaysLeftToFund(5, x.createdAt)

      if (x.funded === undefined || x.funded === null) {
        validInvestments.push(x)
      }
      if (x.funded !== undefined && x.funded !== null && x.funded === true) {
        validInvestments.push(x)
      }
      if (
        x.funded !== undefined &&
        x.funded !== null &&
        x.funded === false &&
        daysLeftToFund_ > 0
      ) {
        validInvestments.push(x)
      }
      if (
        x.funded !== undefined &&
        x.funded !== null &&
        x.funded === false &&
        daysLeftToFund_ < 1
      ) {
        expiredInvestments.push(x)
      }
    })

    validInvestments.sort((a: any, b: any) => {
      const dateA = new Date(a.createdAt).getTime()
      const dateB = new Date(b.createdAt).getTime()
      return dateB - dateA
    })

    if (expiredInvestments.length > 0) {
      const promises = expiredInvestments.map(async (item: any) => {
        return await deleteInvestmentMutation(item.id)
      })

      await Promise.allSettled(promises)
    }

    return validInvestments
  } catch (error) {
    console.log(error)
  }
}

export const createInvestmentMutation = async (investmentDetails: any) => {
  const today = new Date()

  const InterestCycleDays_ = 90
  let expectedInterestPerInterestCycle_ = 0
  let expectedNumberOfInterestCycles_ = 0
  let totalExpectedInterestForInvestment = 0
  const tenorDays_ = Number(investmentDetails.debtOfferings.split(' ')[0])
  const endDate = new Date(today.setDate(today.getDate() + tenorDays_))

  totalExpectedInterestForInvestment = Number(
    (
      Number(
        parseFloat(investmentDetails.principal.replace(/,/g, '')) *
          (investmentDetails.annualRate * 0.01)
      ) *
      (tenorDays_ / 365)
    ).toFixed(2)
  )
  expectedNumberOfInterestCycles_ =
    Math.floor(Number(tenorDays_ / InterestCycleDays_)) || 0
  expectedInterestPerInterestCycle_ =
    Number(
      (
        totalExpectedInterestForInvestment /
        Math.floor(expectedNumberOfInterestCycles_)
      ).toFixed(2)
    ) || 0

  const formData = {
    startDate: new Date(),
    endDate: endDate,
    actualEndDate: null,
    annualInterestRate: investmentDetails.annualRate,
    renewFlag: investmentDetails.autoRenew,
    currency: investmentDetails.currency,
    principleAmount: Number(stringToNumberStr(investmentDetails.principal)),
    InterestCycleDays: InterestCycleDays_,
    tenorDays: tenorDays_,
    expectedTotalInterest: totalExpectedInterestForInvestment,
    expectedInterestPerInterestCycle: expectedInterestPerInterestCycle_,
    expectedNumberOfInterestCycles: expectedNumberOfInterestCycles_,
    Status: 'Active',
    userID: investmentDetails.userID,
    funded: investmentDetails.funded,
    renewOption: investmentDetails.renewOption,
    interestPayoutOption: investmentDetails.interestPayoutOption,
  }

  try {
    const investmentData = (await API.graphql({
      query: createInvestment,
      variables: { input: formData },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<CreateInvestmentMutation>

    //console.log(investmentData.data)
    return investmentData.data
  } catch (error: any) {
    console.log(error)
    if (error.message === 'No current user') {
      await noUserHelper(error)
    }
  }
}

export const updateInvestmentMutation = async (investmentDetails: any) => {
  try {
    const investmentData = (await API.graphql({
      query: updateInvestment,
      variables: { input: investmentDetails },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<UpdateInvestmentMutation>

    return investmentData.data?.updateInvestment
  } catch (error) {
    console.log(error)
  }
}

export const deleteInvestmentMutation = async (investmentId: string) => {
  try {
    const investmentData = (await API.graphql({
      query: deleteInvestment,
      variables: { input: { id: investmentId } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<DeleteInvestmentMutation>

    return investmentData.data?.deleteInvestment
  } catch (error) {
    console.log(error)
  }
}

export const getNextInterestPayoutData = async (investmentId: string) => {
  try {
    const investmentData = (await API.graphql({
      query: getPaymentsByInvestment,
      variables: {
        investmentID: investmentId,
        filter: {
          paymentCategory: { eq: 'Interest' },
          Status: { eq: 'Pending' },
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<GetPaymentsByInvestmentQuery>

    const set: Array<any> = investmentData.data?.getPaymentsByInvestment
      ?.items as any
    set.sort((a: any, b: any) => {
      const dateA = new Date(a.actualDate).getTime()
      const dateB = new Date(b.actualDate).getTime()
      return dateA - dateB
    })

    return set[0]
  } catch (error) {
    console.log(error)
  }
}

export const saveInvestmentAgreementSigning = async (
  signature: string,
  investmentID: string,
  type: AgreementTypes
) => {
  let { success, signingUrl } = { success: false, signingUrl: null } as {
    success: boolean
    signingUrl: string | null
  }

  try {
    const loans = (await API.graphql({
      query: GetSigningUrl,
      variables: {
        signature: signature,
        investmentID: investmentID,
        type: type,
      },
      authMode: 'API_KEY',
    })) as GraphQLResult<GetSigningUrlQuery>

    success = true
    signingUrl = loans.data?.GetSigningUrl?.signatureUrl || null
  } catch (err) {
    console.log(err)
    success = false
    signingUrl = null
  }

  return { success, signingUrl }
}
