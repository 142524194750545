const DatePicker = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8333 2.33329L13 2.33329V0.666626L11.3333 0.666626V2.33329L4.66667 2.33329V0.666626L3 0.666626L3 2.33329L2.16667 2.33329C1.24167 2.33329 0.508333 3.08329 0.508333 3.99996L0.5 15.6666C0.5 16.5833 1.24167 17.3333 2.16667 17.3333L13.8333 17.3333C14.75 17.3333 15.5 16.5833 15.5 15.6666L15.5 3.99996C15.5 3.08329 14.75 2.33329 13.8333 2.33329ZM13.8333 15.6666L2.16667 15.6666L2.16667 7.33329L13.8333 7.33329V15.6666ZM5.5 10.6666H3.83333L3.83333 8.99996H5.5V10.6666ZM8.83333 10.6666H7.16667V8.99996H8.83333V10.6666ZM12.1667 10.6666H10.5V8.99996H12.1667V10.6666ZM5.5 14H3.83333L3.83333 12.3333H5.5V14ZM8.83333 14H7.16667V12.3333H8.83333V14ZM12.1667 14H10.5V12.3333H12.1667V14Z"
        fill="#2F80F6"
      />
    </svg>
  )
}

export default DatePicker
