import { createSlice } from '@reduxjs/toolkit'
import { ILoanbookState } from 'interfaces/loans'

const initialState: ILoanbookState = { loans: [] }

const loanbookSlice = createSlice({
  name: 'loanbook',
  initialState,
  reducers: {
    setLoanbook: (state, action) => {
      state.loans = action.payload
    },
  },
})

export const { setLoanbook } = loanbookSlice.actions
export default loanbookSlice.reducer
