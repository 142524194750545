import './ValidatePassword.scss'
import { Box } from '@mui/material'
import Check from './Check'
import Uncheck from './Uncheck'

interface IValidatePassword {
  isMinLength: boolean
  isUpperCase: boolean
  hasUniqueChar: boolean
  classes?: string
}

const ValidatePassword: React.FC<IValidatePassword> = ({
  isMinLength,
  isUpperCase,
  hasUniqueChar,
  classes,
}: IValidatePassword) => (
  <Box className={`check-password ${classes}`}>
    <Box className="checker">
      {!isMinLength ? <Uncheck /> : <Check />}
      <span data-testid="minLengthUncheck">Minimum of 8 characters</span>
    </Box>
    <Box className="checker">
      {!isUpperCase ? <Uncheck /> : <Check />}
      <span data-testid="upperCaseUncheck">One UPPERCASE letter</span>
    </Box>
    <Box className="checker">
      {!hasUniqueChar ? <Uncheck /> : <Check />}
      <span data-testid="uniqueCharUncheck">
        One unique character (e.g: !@#$%^&*?)
      </span>
    </Box>
  </Box>
)

export default ValidatePassword
