import { TableCell, TableRow, Typography } from '@mui/material'
import { ITableRows } from 'components/DataTable'
import {
  AccountStatementInvestment,
  ITransaction,
  TransactionType,
} from 'interfaces/transactions'
import moment from 'moment'
import React from 'react'
import NumberFormat from 'react-number-format'
import { getCurrencySymbol, sortArrayByField } from 'utils/helpers'

export const ActiveInvestmentsTableRows = ({ data }: ITableRows) => {
  const dataList: AccountStatementInvestment[] = data
    ? sortArrayByField(data, 'startDate', 'asc')
    : []

  return (
    <React.Fragment>
      {dataList &&
        dataList.map(
          (investment: AccountStatementInvestment, index: number) => {
            return (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '.text': {
                    fontFamily: 'Aeonik-Regular',
                    color: '#8083A3',
                    fontSize: '14px',
                    fontWeight: '400',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'left',
                  },
                  '.left': {
                    textAlign: 'left',
                  },
                  '.alt': {
                    color: '#171721',
                  },
                  '.indicator': {
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    mr: '5px',
                  },
                  background: 'white',
                  '.label': {
                    color: '#596A7A',
                    fontSize: '12px',
                    mr: '4px',
                  },
                }}
                data-testid="investment-row"
              >
                <TableCell>
                  <Typography className="text">
                    <span className="label left">
                      {' '}
                      <NumberFormat
                        name="amount"
                        value={investment.amount}
                        displayType={'text'}
                        thousandSeparator={true}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={getCurrencySymbol('USD') || ''}
                      />
                    </span>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="text">
                    <span className="label">{investment.currency}</span>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="text">
                    <span className="label">{investment.apr}%</span>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="text">
                    <span className="label">
                      {' '}
                      <NumberFormat
                        name="amount"
                        value={investment.interestAmount}
                        displayType={'text'}
                        thousandSeparator={true}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={getCurrencySymbol(investment.currency) || ''}
                      />
                    </span>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="text">
                    <span className="label">
                      Every {investment.payoutFrequency} days
                    </span>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="text">
                    <span className="label"> {investment.startDate}</span>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="text">
                    <span className="label">
                      {investment.daysRemaining} days
                    </span>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="text">
                    <span className="label">
                      {investment.autoRenewal ? 'YES' : 'NO'}
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>
            )
          }
        )}
    </React.Fragment>
  )
}

export const TransactionsTableRows = ({ data }: ITableRows) => {
  const dataList: Array<ITransaction> = sortArrayByField(
    data,
    'createdAt',
    'asc'
  )

  return (
    <React.Fragment>
      {dataList &&
        dataList.map((transaction: ITransaction, index: number) => {
          return (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '.text': {
                  fontFamily: 'Aeonik-Regular',
                  color: '#8083A3',
                  fontSize: '14px',
                  fontWeight: '400',
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'left',
                },
                '.alt': {
                  color: '#171721',
                },
                '.indicator': {
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  mr: '5px',
                },
                background: 'white',
                '.label': {
                  color: '#596A7A',
                  fontSize: '14px',
                  mr: '4px',
                },
              }}
              data-testid="transaction-row"
            >
              <TableCell>
                <Typography className="text">
                  <span className="label">
                    {' '}
                    {moment(transaction.createdAt).format('MMM D, YYYY')}
                  </span>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className="text">
                  <span className="label">
                    {transaction.type === TransactionType.InterestPayout ||
                    transaction.type === TransactionType.Interest
                      ? 'Interest'
                      : transaction.type}
                  </span>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className="text">
                  <span className="label">{transaction.currency}</span>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className="text">
                  <NumberFormat
                    name="amount"
                    value={transaction.amount}
                    displayType={'text'}
                    thousandSeparator={true}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={getCurrencySymbol(transaction.currency) || ''}
                  />
                </Typography>
              </TableCell>
            </TableRow>
          )
        })}
    </React.Fragment>
  )
}
