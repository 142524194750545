import { createSlice } from '@reduxjs/toolkit'
import { IFundingState } from 'interfaces/funding'

const initialState: IFundingState = {
  fundAmount: 0,
  currency: '',
  transactionId: null,
  fundingType: null,
}

const fundingSlice = createSlice({
  name: 'funding',
  initialState,
  reducers: {
    setFundData: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setFundData } = fundingSlice.actions
export default fundingSlice.reducer
