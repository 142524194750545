import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialNewInvestment, initialInvestment } from '../data/investmentData'
import { investmentState, NewInvestment } from 'interfaces/investments'

const initialState: investmentState = {
  newInvestment: initialNewInvestment,
  savedInvestment: null,
  investments: initialInvestment,
  isUpdateState: false,
  isSignAgreementState: false,
}

const investmentSlice = createSlice({
  name: 'investment',
  initialState,
  reducers: {
    setNewInvestment: (
      state,
      action: PayloadAction<{
        data: NewInvestment
      }>
    ) => {
      state.newInvestment = action.payload.data
    },
    setSavedInvestment: (state, action) => {
      state.savedInvestment = action.payload.data
    },
    getAllInvestment: (state, action) => {
      state.investments = action.payload
    },
    setUpdateState: (state, action) => {
      state.isUpdateState = action.payload.state
      state.isSignAgreementState = action.payload.isSignAgreementState
        ? true
        : false
    },
  },
})

export const {
  getAllInvestment,
  setNewInvestment,
  setUpdateState,
  setSavedInvestment,
} = investmentSlice.actions

export default investmentSlice.reducer
