import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as Overview } from '../assets/Images/Overview.svg'
import { ReactComponent as HoldMoney } from '../assets/Images/HoldMoney.svg'
import { ReactComponent as Transactions } from '../assets/Images/transactions.svg'
import { ReactComponent as Loanbook } from '../assets/Images/loanbook.svg'
import { ReactComponent as Settings } from '../assets/Images/settings.svg'
import { ReactComponent as Refer } from '../assets/Images/gift.svg'
import { ReactComponent as Statements } from '../assets/Images/notes.svg'

export const navPages = [
  {
    name: 'Dashboard',
    navigation: '/dashboard',
    element: <SvgIcon component={Overview} inheritViewBox color="inherit" />,
    notif: 0,
    coming: false,
  },
  {
    name: 'Investments',
    navigation: '/investments',
    element: <SvgIcon component={HoldMoney} inheritViewBox color="inherit" />,
    notif: 0,
    coming: false,
  },
  {
    name: 'Transactions',
    navigation: '/transactions',
    element: (
      <SvgIcon component={Transactions} inheritViewBox color="inherit" />
    ),
    notif: 0,
    coming: false,
  },
  {
    name: 'Account Statement',
    navigation: '/account-statement',
    element: <SvgIcon component={Statements} inheritViewBox color="inherit" />,
    notif: 0,
    coming: false,
  },
  {
    name: 'Loan Book',
    navigation: '/loanbook',
    element: <SvgIcon component={Loanbook} inheritViewBox color="inherit" />,
    notif: 0,
    coming: false,
  },
]

export const SettingsNav = [
  {
    name: 'Refer & Earn',
    navigation: '/refer',
    element: <SvgIcon component={Refer} inheritViewBox color="inherit" />,
    notif: 0,
    coming: false,
    disabled: true,
  },
  {
    name: 'Settings',
    navigation: '/settings/profile',
    element: <SvgIcon component={Settings} inheritViewBox color="inherit" />,
    notif: 0,
    coming: false,
    // children: [
    //   {
    //     name: 'Profile',
    //     navigation: '/settings/profile',
    //     element: (
    //       <SvgIcon component={Settings} inheritViewBox color="inherit" />
    //     ),
    //     notif: 0,
    //     coming: false,
    //   },
    //   {
    //     name: 'Tax',
    //     navigation: '/settings/tax',
    //     element: (
    //       <SvgIcon component={Settings} inheritViewBox color="inherit" />
    //     ),
    //     notif: 0,
    //     coming: false,
    //   },
    //   {
    //     name: 'Payments',
    //     navigation: '/settings/payments',
    //     element: (
    //       <SvgIcon component={Settings} inheritViewBox color="inherit" />
    //     ),
    //     notif: 0,
    //     coming: false,
    //   },
    //   {
    //     name: 'Security',
    //     navigation: '/settings/security',
    //     element: (
    //       <SvgIcon component={Settings} inheritViewBox color="inherit" />
    //     ),
    //     notif: 0,
    //     coming: false,
    //   },
    // ],
  },
]
