import { Box } from '@mui/material'
import flunaLogo from 'assets/Images/fluna-logo2.svg'

const SplashScreen = () => (
  <Box
    data-testid="splashscreen"
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000,
    }}
  >
    <img
      src={flunaLogo}
      alt="logo"
      loading="lazy"
      style={{ width: '120px' }}
      data-testid="splash"
    />
  </Box>
)

export default SplashScreen
