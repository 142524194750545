import React, { useEffect, useState } from 'react'
import './styles.css'
import { Box, Divider, Grid, Typography } from '@mui/material'
import flunaLogo from '../../../assets/Images/fluna-logo2.png'
import USDFlag from '../../../assets/Images/us-flag.png'
import NGNFlag from '../../../assets/Images/ng.svg'
import NumberFormat from 'react-number-format'
import { formatDate, getCurrencySymbol } from 'utils/helpers'
import DataTable, { ITableHeaderLabel } from 'components/DataTable'
import { IStatementDetails } from 'interfaces/transactions'
import { ActiveInvestmentsTableRows, TransactionsTableRows } from './tableRows'

const ActiveInvestmentsTableHead: Array<ITableHeaderLabel> = [
  {
    label: 'INVESTMENT AMOUNT',
    icon: null,
  },
  {
    label: 'CURRENCY',
    icon: null,
  },
  {
    label: 'APR',
    icon: null,
  },
  {
    label: 'INTEREST AMOUNT',
    icon: null,
  },
  {
    label: 'INVESTMENT PAYOUT FREQUENCY',
    icon: null,
  },
  {
    label: 'START DATE',
    icon: null,
  },
  {
    label: 'DAYS REMAINING',
    icon: null,
  },
  {
    label: 'AUTO RENEWAL',
    icon: null,
  },
]

const TransactionsTableHead: Array<ITableHeaderLabel> = [
  {
    label: 'DATE',
    icon: null,
  },
  {
    label: 'TYPE',
    icon: null,
  },
  {
    label: 'CURRENCY',
    icon: null,
  },
  {
    label: 'AMOUNT',
    icon: null,
  },
]

interface AccountStatementPDFProps {
  data: IStatementDetails
  download?: () => void
}
const AccountStatementPDF = ({ data, download }: AccountStatementPDFProps) => {
  const [details, setDetails] = useState(data)

  useEffect(() => {
    setDetails(data)
  }, [data])
  return (
    <React.Fragment>
      <div
        id={`PdfBoxs`}
        style={{
          width: '100%',
          height: '100%',
          minWidth: '640px',
        }}
      >
        <Box
          component={'div'}
          onClick={download}
          sx={{
            overflowX: 'hidden',
            height: '100%',
            background: 'white',
            '.pdfContainer': {
              position: 'relative',
              background: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              gap: '4rem',
              '.padded': {
                padding: {
                  xs: '2rem 0',
                  sm: '4rem 2rem',
                  md: '4rem 5rem',
                },
              },
              '.logoAndAddress': {
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                mb: '30px',
                '.logo': {
                  width: {
                    xs: '95px',
                    sm: '125px',
                    md: '175px',
                  },
                  height: 'auto',
                  ml: '-10px',
                  mb: '30px',
                  objectFit: 'contain',
                },
                '.address': {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  textAlign: 'right',
                  color: '#8083A3',
                  fontSize: {
                    xs: '11px',
                    sm: '14px',
                    md: '16px',
                  },
                  fontWeight: 400,
                },
              },
              '.title': {
                position: 'relative',
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                textAlign: 'start',
                '.main': {
                  color: '#17224D',
                  fontSize: {
                    xs: '14px',
                    sm: '20px',
                    md: '30px',
                  },
                },
                '.sub': {
                  color: '#86929D',
                  fontSize: {
                    xs: '11px',
                    sm: '14px',
                    md: '20px',
                  },
                },
              },
              '.investor': {
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                textAlign: 'start',
                '.label': {
                  color: '#86929D',
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                    md: '16px',
                  },
                },
                '.name': {
                  color: '#17224D',
                  fontSize: {
                    xs: '10px',
                    sm: '16px',
                    md: '26px',
                  },
                },
                '.address': {
                  color: '#86929D',
                  fontSize: {
                    xs: '8px',
                    sm: '10px',
                    md: '14px',
                  },
                },
              },
              '.balances': {
                position: 'relative',
                maxWidth: '100%',
                height: 'auto',
                padding: {
                  xs: '2rem 0',
                  sm: '4rem 2rem',
                  md: '4rem 5rem',
                },
                background: 'rgba(207, 214, 232, 0.16)',
                '.label': {
                  color: '#86929D',
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                    md: '16px',
                  },
                  '.alt': {
                    color: '#B8C2CC',
                  },
                },
                '.item': {
                  padding: '20px 0',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                  textAlign: 'left',
                  gap: '10px',
                  '.label': {
                    color: '#8083A3',
                    fontSize: '14px',
                  },
                  '.value': {
                    color: '#171721',
                    fontSize: {
                      xs: '14px',
                      sm: '16px',
                      md: '20px',
                    },
                    '.flag': {
                      width: '24px',
                      height: '14px',
                      borderRadius: '2px',
                    },
                  },
                },
              },
              '.activeInvestments': {
                position: 'relative',
                width: '100%',
                '.title': {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'left',
                },
                '.main': {
                  color: '#596A7A',
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                    md: '22px',
                  },
                },
                '.sub': {
                  color: '#86929D',
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                    md: '16px',
                  },
                  '.alt': {
                    color: '#B8C2CC',
                  },
                },
                '.activeInvestmentsData': {
                  position: 'relative',
                  mt: '3rem',
                  mb: '3rem',
                  width: '100%',
                  '.tableContainer': {
                    background: 'white',
                    '.headerTxt': {
                      textAlign: 'left',
                      color: '#86929D',
                      fontSize: {
                        xs: '10px',
                        sm: '10px',
                        md: '12px',
                      },
                    },
                  },
                },
                mb: '50px',
              },
              '.transactionActivity': {
                position: 'relative',
                width: '100%',
                '.title': {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'left',
                },
                '.main': {
                  color: '#596A7A',
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                    md: '22px',
                  },
                },
                '.sub': {
                  color: '#86929D',
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                    md: '16px',
                  },
                  '.alt': {
                    color: '#B8C2CC',
                  },
                },
                '.transactionsData': {
                  position: 'relative',
                  mt: '3rem',
                  mb: '3rem',
                  width: '100%',
                  '.tableContainer': {
                    background: 'white',
                    '.headerTxt': {
                      textAlign: 'left',
                      color: '#86929D',
                      fontSize: {
                        xs: '10px',
                        sm: '10px',
                        md: '12px',
                      },
                    },
                  },
                },
              },

              '.footer': {
                position: 'relative',
                mt: '9rem',
                width: '100%',
                height: '80px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F8F8FC',
                '.text': {
                  color: '#86929D',
                  fontSize: {
                    xs: '10px',
                    sm: '10px',
                    md: '12px',
                  },
                },
              },
            },
          }}
        >
          <div className="pdfContainer">
            <div className="padded">
              <Typography component={'div'} className="logoAndAddress">
                <div className="logo">
                  <img
                    alt="Fluna"
                    src={flunaLogo}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#fff',
                    }}
                  />
                </div>
                <div className="address">
                  <label>
                    +1 (201) 472-3780 256 <br />
                    Chapman Road, Newark, DE 19702 <br />
                    help@fluna.co
                  </label>
                </div>
              </Typography>

              <Typography component={'div'} className="title">
                <label className="main">INVESTMENT STATEMENT</label>
                <label className="sub">{details?.currentQuarter || ''}</label>
              </Typography>

              <Typography component={'div'} className="investor">
                <label className="label">INVESTOR</label>
                <label className="name">{details.fullName}</label>
                <label className="address">{details.investorAddress}</label>
              </Typography>
            </div>

            <Box component={'div'} className="balances">
              <label className="label">
                <span className="alt">As of:</span>{' '}
                {formatDate(new Date(details.endDate)) || ''}
              </label>
              {/* USD */}
              <Grid container>
                <Grid item xs={4}>
                  <div className="item">
                    <label className="label">Currency</label>
                    <label className="value">
                      USD <img src={USDFlag} className="flag" />
                    </label>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="item center">
                    <label className="label">Active Investment</label>
                    <label className="value">
                      <NumberFormat
                        name="amount"
                        value={details.activeInvestmentBalance.USD}
                        displayType={'text'}
                        thousandSeparator={true}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={getCurrencySymbol('USD') || ''}
                      />
                    </label>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="item center">
                    <label className="label">Interest Earned</label>
                    <label className="value">
                      <NumberFormat
                        name="amount"
                        value={details.interestEarned.USD}
                        displayType={'text'}
                        thousandSeparator={true}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={getCurrencySymbol('USD') || ''}
                      />
                    </label>
                  </div>
                </Grid>

                {/* <Grid item xs={3}>
                  <div className="item center">
                    <label className="label">Fluna Wallet Balance</label>
                    <label className="value">
                      <NumberFormat
                        name="amount"
                        value={details.walletBalances.USD}
                        displayType={'text'}
                        thousandSeparator={true}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={getCurrencySymbol('USD') || ''}
                      />
                    </label>
                  </div>
                </Grid> */}
              </Grid>

              {/* NGN */}
              {details.hasNGNWallet && (
                <React.Fragment>
                  <Divider />
                  <Grid container>
                    <Grid item xs={4}>
                      <div className="item">
                        <label className="label">Currency</label>
                        <label className="value">
                          NGN <img src={NGNFlag} className="flag" />
                        </label>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="item center">
                        <label className="label">Active Investment</label>
                        <label className="value">
                          <NumberFormat
                            name="amount"
                            value={details.activeInvestmentBalance.NGN}
                            displayType={'text'}
                            thousandSeparator={true}
                            allowNegative={false}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            prefix={'NGN'}
                          />
                        </label>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="item center">
                        <label className="label">Interest Earned</label>
                        <label className="value">
                          <NumberFormat
                            name="amount"
                            value={details.interestEarned.NGN}
                            displayType={'text'}
                            thousandSeparator={true}
                            allowNegative={false}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            prefix={'NGN'}
                          />
                        </label>
                      </div>
                    </Grid>

                    {/* <Grid item xs={4}>
                      <div className="item center">
                        <label className="label">Fluna Wallet Balance</label>
                        <label className="value">
                          <NumberFormat
                            name="amount"
                            value={details.walletBalances.NGN}
                            displayType={'text'}
                            thousandSeparator={true}
                            allowNegative={false}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            prefix={'NGN'}
                          />
                        </label>
                      </div>
                    </Grid> */}
                  </Grid>
                </React.Fragment>
              )}
            </Box>

            <div className="padded">
              <Box component={'div'} className="activeInvestments">
                <Typography component={'div'} className="title">
                  <label className="main">Active Investments</label>

                  <label className="sub">
                    <span className="alt">As of</span>:{' '}
                    {formatDate(new Date(details.endDate)) || ''}
                  </label>
                </Typography>

                <Box component={'div'} className="activeInvestmentsData">
                  <DataTable
                    headerLabels={ActiveInvestmentsTableHead}
                    isLoading={false}
                    noData={
                      details.activeInvestments.length === 0 ? true : false
                    }
                    noDataText={'No Active Investments'}
                    noFilteredData={false}
                    noFilteredDataText={''}
                  >
                    <ActiveInvestmentsTableRows
                      data={details.activeInvestments}
                    />
                  </DataTable>
                </Box>
              </Box>

              <Box component={'div'} className="transactionActivity">
                <Typography component={'div'} className="title">
                  <label className="main">TRANSACTION ACTIVITY</label>

                  <label className="sub">
                    <span className="alt">Period</span>: {details.currentPeriod}
                  </label>
                </Typography>

                <Box component={'div'} className="transactionsData">
                  <DataTable
                    headerLabels={TransactionsTableHead}
                    isLoading={false}
                    noData={details.transactions.length === 0 ? true : false}
                    noDataText={'No Recent Transactions'}
                    noFilteredData={false}
                    noFilteredDataText={''}
                  >
                    <TransactionsTableRows data={details.transactions} />
                  </DataTable>
                </Box>
              </Box>

              <Typography component={'div'} className="footer">
                <label className="text">
                  If you need any help, please reach out to us at help@fluna.co
                </label>
              </Typography>
            </div>
          </div>
        </Box>
      </div>
    </React.Fragment>
  )
}

export default AccountStatementPDF
