import React from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
const RouteChangeTracker = () => {
  const location = useLocation()
  React.useEffect(() => {
    ReactGA.pageview(location.pathname)
  }, [location])

  return <></>
}

export default RouteChangeTracker
