import { Box } from '@mui/material'
import './FormHeader.scss'

interface FormHeaderProps {
  smallText?: string
  mainText?: string
  isLarge?: boolean
  isTiny?: boolean
  isMain?: boolean
  leadText?: string
  leadText2?: string
  color?: boolean
  leadColor?: string
}

const FormHeader = ({
  smallText,
  mainText,
  isLarge,
  isTiny,
  leadText,
  leadText2,
  color,
  leadColor,
}: FormHeaderProps) => {
  const coloredText = color && mainText && mainText.split(' ').slice(-1)[0]
  const lastIndex = mainText?.lastIndexOf(' ')
  mainText = color ? mainText && mainText.substring(0, lastIndex) : mainText

  return (
    <Box className="form-header">
      {smallText && (
        <p className={`${!isTiny ? 'small-text' : 'tiny-text'}`}>{smallText}</p>
      )}
      {mainText && (
        <h2 className={`${isLarge ? 'large-text' : 'main-text'}`}>
          {mainText}{' '}
          {color && <span className={'colored-text'}>{coloredText}</span>}
        </h2>
      )}
      {leadText && <p className={`lead-text ${leadColor}`}>{leadText}</p>}
      {leadText2 && <p className="lead-text2">{leadText2}</p>}
    </Box>
  )
}

export default FormHeader
