import { createSlice } from '@reduxjs/toolkit'
import { IAccountBalances } from 'interfaces/dashboard'

const initialState = {
  balances: {
    walletBalance: { USD: 0, NGN: 0 },
    activeInvestment: { USD: 0, NGN: 0 },
    totalBalance: { USD: 0, NGN: 0 },
    lifetimeInvestments: { USD: 0, NGN: 0 },
    lifetimeInterestEarned: { USD: 0, NGN: 0 },
    weightedAnnualInterestRate: { USD: 0, NGN: 0 },
    nextInterestPayout: 0,
    lastWithdrawalAmount: 0,
  },
  balancesSet: false,
} as { balances: IAccountBalances; balancesSet: boolean }

const accountBalancesSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    getAllBalance: (state, action) => {
      state.balances = action.payload
      state.balancesSet = true
    },
  },
})
export const { getAllBalance } = accountBalancesSlice.actions
export default accountBalancesSlice.reducer
