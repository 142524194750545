import { createSlice } from '@reduxjs/toolkit'
import { ISelectedItem, sendMailPayload } from 'interfaces/dashboard'
import { customGenericMailSender } from 'graphql/queries'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { CustomGenericMailSenderQuery } from 'API'
import { API } from 'aws-amplify'
import ErrorHandler from 'utils/ErrorHandler'
import SuccessHandler from 'utils/SuccessHandler'

const initialState = { type: '', value: {} } as ISelectedItem

export const sendMail = async (payload: sendMailPayload) => {
  const {
    showNotif,
    recipientMails,
    mailSubject,
    mailBody,
    ccMails,
    bccMails,
    replyAddresses,
  } = payload
  try {
    const response = (await API.graphql({
      query: customGenericMailSender,
      variables: {
        recipientMails: recipientMails,
        mailSubject: mailSubject,
        mailBody: mailBody,
        ccMails: ccMails,
        bccMails: bccMails,
        replyAddresses: replyAddresses,
      },
      authMode: 'API_KEY',
    })) as GraphQLResult<CustomGenericMailSenderQuery>
    if (showNotif) {
      SuccessHandler({ message: 'Mail Sent Successfully' })
    }
    return {
      status: 'Success',
      data: JSON.parse(response?.data?.customGenericMailSender as string),
    }
  } catch (err: any) {
    ErrorHandler({ message: 'Unable to send mail' })
    return { status: 'Error', error: err }
  }
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    selectItem(state, action) {
      return { type: action.payload.dataType, value: action.payload.data }
    },
  },
})

export const { selectItem } = dashboardSlice.actions
export default dashboardSlice.reducer
