import AuthGuard from 'auth/AuthGuard'
import ResetPassword from 'pages/authentication/resetPassword'
import React, { lazy } from 'react'

// Authentication pages
export const Login = lazy(() => import('pages/authentication/login'))
export const Register = lazy(() => import('pages/authentication/register'))
export const EmailSent = lazy(() => import('pages/authentication/emailsent'))
export const CodeVerification = lazy(
  () => import('pages/authentication/emailsent/CodeVerification')
)
export const TransferAcknowledgement = lazy(
  () => import('pages/fund/transferAcknowledgement')
)
export const VerifyAuthCode = lazy(
  () => import('pages/authentication/verifycode')
)
export const VerifyAuthCodeWithdraw = lazy(
  () => import('pages/authentication/verifycode/verifycode')
)
export const SuccessPage = lazy(() => import('pages/authentication/success'))
export const ForgotPassword = lazy(
  () => import('pages/authentication/forgotpassword')
)
export const UserDetails = lazy(
  () => import('pages/authentication/userdetails')
)
export const AccountReady = lazy(() => import('pages/authentication/account'))
export const TermsAndConditions = lazy(
  () => import('pages/authentication/TermsAndConditions/TermsAndConditions')
)
export const PrivacyPolicy = lazy(
  () => import('pages/authentication/TermsAndConditions/PrivacyPolicy')
)

//CHILDREN ARRAY
const AuthRoutes = [
  {
    path: 'signup',
    element: <Register />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'email-sent/:username',
    element: <EmailSent />,
  },
  {
    path: 'verify',
    element: <VerifyAuthCodeWithdraw />,
  },
  {
    path: 'verify-code',
    element: <VerifyAuthCode />,
  },
  {
    path: 'code-verification',
    element: <CodeVerification />,
  },
  {
    path: 'funding/transfer-sent/:id',
    element: <TransferAcknowledgement />,
  },
  {
    path: 'fx/transfer-sent/:id',
    element: <TransferAcknowledgement />,
  },
  {
    path: 'success',
    element: <SuccessPage />,
  },
  {
    path: 'reset-password/*',
    element: <ResetPassword />,
  },
  {
    path: 'forgot-password/*',
    element: <ForgotPassword />,
  },
  {
    path: 'account/*',
    element: <AccountReady />,
  },
  {
    path: 'user/*',
    element: (
      <AuthGuard>
        <UserDetails />
      </AuthGuard>
    ),
  },
  {
    path: 'terms-and-conditions',
    element: <TermsAndConditions />,
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '',
    element: <Login />,
  },
]

export default AuthRoutes
