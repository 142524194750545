/* eslint-disable */
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Modal,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import DataTable, { ITableHeaderLabel, ITableRows } from 'components/DataTable'
import AccountStatementPDF from 'components/AccountStatements/AccountStatementPDF'
import React, { useEffect, useState } from 'react'
import { Quarter } from 'interfaces/bank'
import { getPastQuarters } from 'utils/helpers'
import DownloadIcon from '@mui/icons-material/Download'
import { useAppSelector } from 'redux/hooks'
import { RootState } from 'redux/store'
import { getAccountStatementData } from 'services/AccountService'
import { IStatementDetails } from 'interfaces/transactions'
import SuccessHandler from 'utils/SuccessHandler'
import ErrorHandler from 'utils/ErrorHandler'
import generatePDF from 'utils/pdfUtils'
import { getInvestmentData } from 'services/investmentServices'

const tableHead: Array<ITableHeaderLabel> = [
  {
    label: 'STATEMENT PERIOD',
    icon: null,
  },
  {
    label: 'ACTION',
    icon: null,
  },
]

const TableRows = ({
  data,
  action,
  downloadComplete,
  disableAction,
}: ITableRows) => {
  const dataList: Array<Quarter> = data

  return (
    <React.Fragment>
      {dataList &&
        dataList.map((row: Quarter, index: number) => {
          const [showAction, setShowAction] = useState(false)
          const [currentDownloadIndex, setCurrentDownloadIndex] = useState<
            number | null
          >(null)

          const onHover = () => {
            setShowAction(true)
          }

          const onExit = () => {
            setShowAction(false)
          }

          const handleAction = (index: number) => {
            const params = {
              startDate: row.startDate,
              endDate: row.endDate,
              currentQuarter: row.label,
              currentPeriod: row.period,
            }
            action && action(params)
            setCurrentDownloadIndex(index)
          }

          useEffect(() => {
            if (downloadComplete === true) {
              setCurrentDownloadIndex(null)
            }
          }, [downloadComplete])
          return (
            <TableRow
              key={index}
              onMouseEnter={onHover}
              onMouseLeave={onExit}
              data-testid="tableRow"
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '.text': {
                  fontFamily: 'Aeonik-Regular',
                  color: '#8083A3',
                  fontSize: '14px',
                  fontWeight: '400',
                },
                '.alt': {
                  color: '#171721',
                },
                '.indicator': {
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  mr: '5px',
                },
                background: showAction ? 'white' : 'transparent',
                '.label': {
                  color: '#596A7A',
                  fontSize: '14px',
                  mr: '4px',
                },
                '.period': {
                  color: '#86929D',
                  fontSize: '14px',
                },
                '.download': {
                  position: 'relative',
                  background: 'white',
                  border: '1px solid rgba(0, 127, 255, 0.25)',
                  borderRadius: '4px',
                  boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.04)',
                  display: 'flex',
                  justifyContent: 'center',
                  width: 'auto',
                  minWidth: '166px',
                  height: '36px',
                  fontSize: '13px',
                  fontWeight: '500',
                  lineHeight: '20px',
                  color: '#57584E',
                  textTransform: 'none',
                  cursor: 'pointer',
                  ':disabled': {
                    color: '#57584E',
                  },
                  '.icon': {
                    mr: '5px',
                    color: '#909090',
                  },
                },
              }}
            >
              <TableCell>
                <Typography className="text">
                  <span className="label">{row.label}</span>
                  <span className="period">({row.period})</span>
                </Typography>
              </TableCell>
              <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography className="text alt">
                  <Button
                    className="download"
                    onClick={() => handleAction(index)}
                    disabled={disableAction !== null ? disableAction : false}
                    data-testid={index === 0 ? 'downloadAccountStatement' : ''}
                  >
                    {currentDownloadIndex === index ? (
                      <CircularProgress
                        size={'20px'}
                        sx={{ color: '#949494' }}
                      />
                    ) : (
                      <>
                        {' '}
                        <DownloadIcon className="icon" />{' '}
                        <span>Download Statement</span>
                      </>
                    )}
                  </Button>
                </Typography>
              </TableCell>
            </TableRow>
          )
        })}
    </React.Fragment>
  )
}

const AccountStatements = () => {
  const user = useAppSelector((state: RootState) => state.auth.user)
  const [isDownloading, setIsDownloading] = useState<boolean | null>(null)
  const [isGenerating, setIsGenerating] = useState<boolean | null>(null)
  const [accountStatementData, setAccountStatementData] = useState<
    IStatementDetails | null | any
  >(null)
  const [openStatementModal, setOpenStatementModal] = useState(false)

  const [quarters, setQuarters] = useState<Quarter[]>([])
  const [currentQuarter, setCurrentQuarter] = useState('')
  const [noPastQuarters, setNoPastQuarters] = useState(false)
  const [retrievedQuarters, setRetrievedQuarters] = useState<
    { quarter: string; data: IStatementDetails | null }[]
  >([])

  const getAccountStatementData_ = async (params: any) => {
    const { success, data } = await getAccountStatementData(
      user.id,
      params.startDate,
      params.endDate,
      params.currentQuarter,
      params.currentPeriod
    )
    if (success) {
      return data
    }
    ErrorHandler({ message: 'Error creating account statement' })
    return null
  }

  const handleOpenStatementModal = () => {
    setOpenStatementModal(true)
  }

  const handleCloseStatementModal = () => {
    setOpenStatementModal(false)
  }

  const handleGetStatementAndDownload = async (params: any) => {
    if (isGenerating) {
      ErrorHandler({
        message: 'Please Wait for the current PDF to finish loading',
      })
      return
    }

    const currentQuarter = params.currentQuarter
    const existingQuarter = retrievedQuarters.find(
      (item) => item.quarter === currentQuarter
    )

    if (existingQuarter) {
      setCurrentQuarter(currentQuarter)
      setAccountStatementData(existingQuarter.data)
      handleOpenStatementModal()
      return
    }

    setIsGenerating(true)
    SuccessHandler({
      message: 'Generating Account Statement PDF',
    })
    setCurrentQuarter(params.currentQuarter)

    const data = await getAccountStatementData_(params)
    if (data !== null) {
      const statementData = { ...data, endDate: params.endDate }
      setAccountStatementData(statementData)
      setRetrievedQuarters([
        ...retrievedQuarters,
        { quarter: currentQuarter, data: statementData },
      ])
      handleOpenStatementModal()
      setIsGenerating(false)
      return
    }
    ErrorHandler({ message: 'Error creating account statement' })
  }

  const handleDownloadPdf = async () => {
    setIsDownloading(true)

    const timestamp = new Date().getTime().toString().slice(0, 4)
    await generatePDF(
      'PdfBox',
      `AccountStatement_${currentQuarter}_${timestamp}`
    )
    setIsDownloading(false)

    handleCloseStatementModal()
  }

  const getEarliestInvestment = async () => {
    const investments = (await getInvestmentData(user.id)) || []
    const fundedInvestments = investments.filter(
      (investment) => investment.funded
    )

    if (fundedInvestments.length === 0) {
      return null // Return an empty string or any other default value you prefer
    }

    if (fundedInvestments.length === 1) {
      return fundedInvestments[0].startDate // Return the start date of the single funded investment
    }

    const earliestStartDate = fundedInvestments.reduce((earliest, current) => {
      if (new Date(current.startDate) < new Date(earliest.startDate)) {
        return current
      }
      return earliest
    }).startDate

    return earliestStartDate
  }

  const getQuarters = async () => {
    const startDate = await getEarliestInvestment()

    if (startDate === null) {
      setNoPastQuarters(true)
      setQuarters([])
      return
    }

    const currentDate = new Date().toISOString()
    const quartersData = getPastQuarters(startDate, currentDate)

    if (quartersData.length === 0) {
      setNoPastQuarters(true)
      setQuarters([])
      return
    }

    setQuarters(quartersData)
    setNoPastQuarters(false)
  }

  useEffect(() => {
    if (user) {
      getQuarters()
    }
  }, [user])

  return (
    <>
      <Modal
        open={openStatementModal}
        onClose={handleCloseStatementModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={true}
        sx={{
          padding: {
            xs: '10rem 0rem',
            sm: '8rem 5rem',
            md: '4rem 10rem',
            lg: '5rem 15rem',
          },
          '.modalBox': {
            scale: '1',
            height: '100%',
            background: '#cecece',
            '.downloadBox': {
              display: 'flex',
              justifyContent: 'center',
              padding: '10px',
              '.btn': {
                position: 'relative',
                background: 'white',
                border: '1px solid rgba(0, 127, 255, 0.25)',
                borderRadius: '12px',
                boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.04)',
                display: 'flex',
                justifyContent: 'center',
                width: 'auto',
                minWidth: '116px',
                height: '36px',
                fontSize: '13px',
                fontWeight: '500',
                lineHeight: '20px',
                color: '#57584E',
                textTransform: 'none',
                '.icon': {
                  mr: '5px',
                  color: '#909090',
                },
              },
            },
          },
        }}
      >
        <div className="modalBox">
          {' '}
          <Typography className="downloadBox">
            <Button
              className="btn"
              onClick={handleDownloadPdf}
              data-testid="downloadPDF"
            >
              {isDownloading ? (
                <CircularProgress size={'20px'} sx={{ color: '#949494' }} />
              ) : (
                <>
                  {' '}
                  <DownloadIcon className="icon" />{' '}
                  <span>Download Statement</span>
                </>
              )}
            </Button>
          </Typography>
          <div id={`PdfBox`} style={{ overflowY: 'scroll', height: '100%' }}>
            <AccountStatementPDF data={accountStatementData} />
          </div>
        </div>
      </Modal>

      <Container
        disableGutters
        maxWidth={false}
        sx={{
          display: 'block',
          padding: {
            xs: '2rem 0',
            sm: '4rem 2rem',
            md: '4rem 5rem',
          },
          overflowX: 'hidden',
        }}
      >
        <Box
          component={'div'}
          sx={{
            position: 'relative',
            top: '1rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            width: '100%',
            '.main': {
              color: '#17224D',
              fontSize: '24px',
              fontWeight: '500',
            },
            '.sub': {
              color: '#596A7A',
              fontSize: '14px',
              fontWeight: '300',
            },
          }}
        >
          <Typography component={'label'} className="main">
            Account Statement
          </Typography>
          <Typography component={'label'} className="sub">
            Click your statement period to view & download your quarterly
            account statements for all your investments on Fluna
          </Typography>
        </Box>

        <Box
          component={'div'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            width: '100%',
            height: '100%',
            position: 'relative',
            padding: '4rem 0',
            '.tableContainer': {
              background: 'transparent',
              '.lastItem': {
                display: 'flex',
                justifyContent: 'center',
              },
            },
          }}
        >
          <DataTable
            headerLabels={tableHead}
            isLoading={false}
            noData={noPastQuarters}
            noDataText={'No statements available'}
            noFilteredData={false}
            noFilteredDataText={''}
          >
            {quarters.length > 0 && (
              <TableRows
                data={quarters}
                action={handleGetStatementAndDownload}
                downloadComplete={openStatementModal === false}
                disableAction={isGenerating}
              />
            )}
          </DataTable>
        </Box>
      </Container>
    </>
  )
}

export default AccountStatements
