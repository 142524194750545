import { Box, MenuItem, Select } from '@mui/material'
import './Input.scss'
import { useEffect, useState } from 'react'
import Eye from './Eye'
import EyeClosed from './EyeClosed'
import Good from './Good'
import DatePicker from 'SVGs/DatePicker'
import Info from 'SVGs/Info'
import Tooltip from '@mui/material/Tooltip'

interface Currency {
  value: string
  label: string
  flag: string
}

interface InputProps {
  name: string
  id?: string
  labelName?: string
  classes?: string
  type: string
  placeholder?: string
  value?: number | string
  required?: boolean
  handleFocus?: React.FocusEventHandler<HTMLInputElement>
  handleBlur?: React.FocusEventHandler<HTMLInputElement>
  passwordMatch?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  autoFocus?: boolean
  currencies?: Currency[]
  hideCurrencyPickerIcon?: boolean
  hasInfoIcon?: boolean
  isDisabled?: boolean
  pattern?: string
  errorMessage?: boolean
  errors?: Record<string, string>
  touched?: Record<string, boolean>
  tooltipText?: string
  datePickerCallback?: () => void
  selectedCurrency?: string
  currencyPickerCallback?: (option: any) => void
  style?: Record<string, string | number>
  dataTestId?: string
  fillWithMax?: boolean
  action?: () => void
}

const Input = ({
  name,
  labelName,
  classes,
  type,
  placeholder,
  value,
  onChange,
  handleFocus,
  handleBlur,
  passwordMatch,
  currencies,
  hideCurrencyPickerIcon,
  isDisabled,
  hasInfoIcon,
  errors = {},
  // touched = {},
  datePickerCallback,
  selectedCurrency,
  currencyPickerCallback,
  tooltipText,
  dataTestId,
  fillWithMax,
  action,
}: InputProps) => {
  const [showText, setShowtext] = useState<boolean>(false)
  const [currency, setCurrency] = useState<string | undefined>(
    currencies?.[0]?.value ?? ''
  )
  const toggleType = () => {
    setShowtext((prev) => !prev)
  }

  const handleCurrency = (option: any) => {
    setCurrency(option)
    currencyPickerCallback && currencyPickerCallback(option)
  }
  const error = errors[name]

  useEffect(() => {
    if (selectedCurrency !== undefined) {
      setCurrency(selectedCurrency)
    }
  }, [selectedCurrency])
  return (
    <Box
      className={`input ${classes || ''} ${
        type === 'password' ? 'password-input' : ''
      } `}
    >
      {labelName && (
        <label htmlFor={`input-${name}`} className="label">
          {labelName}
        </label>
      )}{' '}
      {hasInfoIcon ? (
        <span
          style={{
            cursor: 'pointer',
            display: 'inline-flex',
            verticalAlign: 'text-bottom',
            position: 'relative',
            top: '3px',
          }}
        >
          {tooltipText && (
            <Tooltip
              title={tooltipText}
              arrow
              placement="top"
              className="tooltip-class"
            >
              <span>
                <Info w="16" h="16" />
                {''}
              </span>
            </Tooltip>
          )}{' '}
        </span>
      ) : (
        ''
      )}
      <Box className="input-wrap">
        {currencies && currencies.length > 0 && (
          <Box>
            <Select
              value={currency}
              onChange={(event) => {
                handleCurrency(event.target.value)
              }}
              disabled={isDisabled}
              className="flag-selecter"
              IconComponent={hideCurrencyPickerIcon ? () => null : undefined}
              data-testid="currencyBox"
            >
              {currencies.map((currency) => (
                <MenuItem key={currency.value} value={currency.value}>
                  <img
                    src={currency.flag}
                    alt={currency.label}
                    style={{ marginRight: '8px' }}
                  />{' '}
                  {currency.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        <input
          autoComplete={'off'}
          name={name}
          value={value}
          type={showText ? 'text' : type || 'text'}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          max={type === 'date' ? new Date().toISOString().split('T')[0] : ''}
          required
          className={`${error ? 'input-error' : 'input'} input`}
          disabled={isDisabled}
          id={`input-${name}`}
          data-testid={dataTestId}
          style={{
            padding: fillWithMax ? '8px 50px' : '8px 16px',
          }}
        />
        {type === 'password' &&
          (name === 'confirmPassword' ? (
            <div
              onClick={toggleType}
              className="eye"
              data-testid="password-eye"
            >
              {showText ? <Eye /> : <EyeClosed />}
            </div>
          ) : passwordMatch ? (
            <div className="valid">
              <Good />
            </div>
          ) : (
            <div
              onClick={toggleType}
              className="eye"
              data-testid="password-eye"
            >
              {showText ? <Eye /> : <EyeClosed />}
            </div>
          ))}
        {type === 'date' && (
          <Box className="date" onClick={datePickerCallback}>
            <DatePicker />
          </Box>
        )}

        {fillWithMax && (
          <>
            <Box
              className="eye"
              onClick={action}
              data-testid="maxButton"
              sx={{
                position: 'relative',
                top: '8px',
                height: 'inherit',
                '.max': {
                  ml: '-40px',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#86929D',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
            >
              <label className="max">Max</label>
            </Box>
          </>
        )}
      </Box>
      {error && (
        <Box
          component="span"
          sx={{
            display: 'block',
            marginTop: '-10px',
            color: 'red',
            fontSize: '12px',
            marginBottom: '2px',
            textAlign: 'right',
          }}
          className="password_error error"
        >
          {errors[name]}
        </Box>
      )}
    </Box>
  )
}

export default Input
