/* tslint:disable */
/* eslint-disable */

import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

const determineScale = (element: any) => {
  const maxWidth = 800
  if (element.scrollWidth > maxWidth) {
    return maxWidth / element.scrollWidth
  }
  return 1
}

const generatePDF = async (divId: string, title: string) => {
  const element = document.getElementById(divId)
  if (!element) {
    console.error('Element with provided ID not found')
    return
  }

  element.scrollTop = 0

  const originalStyle = element.getAttribute('style')
  element.style.overflow = 'visible'
  element.style.height = 'auto'

  const scale = determineScale(element)
  const qualityScale = 2

  const canvas = await html2canvas(element, {
    logging: true,
    useCORS: true,
    scale: scale * qualityScale, //1.2,
    windowWidth: element.scrollWidth * 1.2,
    windowHeight: element.scrollHeight,
    x: 0,
    y: 0,
    scrollX: 0,
    scrollY: 0,
  })

  element.setAttribute('style', originalStyle || '')
  const imageData = canvas.toDataURL('image/png')
  const imageWidth = 208
  const imageHeight = (canvas.height * imageWidth) / canvas.width

  const doc = new jsPDF({
    orientation: 'portrait',
    format: 'a4',
    unit: 'mm',
  })

  doc.addImage(
    imageData,
    'PNG',
    0,
    0,
    imageWidth,
    doc.internal.pageSize.getHeight() < imageHeight
      ? doc.internal.pageSize.getHeight()
      : imageHeight
  )

  doc.save(title)
}

export default generatePDF
