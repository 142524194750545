/* eslint-disable */
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { API, Auth } from 'aws-amplify'
import { useFormik } from 'formik'
import Button from 'components/Button'
import FormComp from 'components/FormComp'
import { useNavigate } from 'react-router'
import ErrorHandler from 'utils/ErrorHandler'
import FormHeader from 'components/FormHeader'
import FormWrapper from 'components/FormWrapper'
import SuccessHandler from 'utils/SuccessHandler'
import PasswordComp from 'components/PasswordComp'
import { useSearchParams } from 'react-router-dom'
import { Validation } from './Validation'
import Input from 'components/Input'
import errorHandler from 'utils/ErrorHandler'
import { ChallengeResponse } from 'interfaces/authState'
import { GraphQLResult } from '@aws-amplify/api'
import { MigrationStatus, OnboardingStatus, UpdateUserMutation } from 'API'
import { updateUser } from 'graphql/mutations'

const ResetPassword = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const username = searchParams.get('username') || ''
  const [loading, setLoading] = useState(false)

  const initialValues = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Validation,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const result = await completeNewPassword(
          username,
          values.oldPassword.trim(),
          values.password.trim()
        )
        if (result?.state) {
          SuccessHandler({
            message: 'Your password reset was successfully',
          })

          setTimeout(() => {
            navigate('/login?reset=true', {
              state: {
                text: 'Password updated successfully.',
              },
            })
          }, 1200)
        }
      } catch (error: any) {
        if (error.message) {
          ErrorHandler(error)
        } else {
          ErrorHandler()
        }
      } finally {
        setLoading(false)
      }
    },
  })

  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    isValid,
    handleSubmit,
  } = formik

  const goBack = () => {
    navigate(-1)
  }

  const updateUserMigrationStatus = async (uid: string) => {
    const payload = {
      id: uid,
      onboardingStatus: OnboardingStatus.completed,
      migrationStatus: MigrationStatus.online,
    }

    try {
      const userData = (await API.graphql({
        query: updateUser,
        variables: { input: payload },
        authMode: 'API_KEY',
      })) as GraphQLResult<UpdateUserMutation>
      return userData.data?.updateUser
    } catch (error) {
      console.log(error)
    }
  }

  const completeNewPassword = async (
    email: string,
    oldPassword: string,
    newPassword: string
  ) => {
    let { state, data } = { state: false, data: null }
    try {
      if (oldPassword === newPassword) {
        errorHandler({ message: 'Cannot reuse previous password' })
        return
      }

      const cognitoUser = await Auth.signIn(email, oldPassword)

      if (
        cognitoUser.challengeName === ChallengeResponse.NEW_PASSWORD_REQUIRED
      ) {
        await Auth.completeNewPassword(cognitoUser, newPassword.trim())
        await updateUserMigrationStatus(cognitoUser.username)
        state = true
      }

      if (cognitoUser.challengeName === ChallengeResponse.CUSTOM_CHALLENGE) {
        navigate('/login')
        return
      }
    } catch (err: any) {
      state = false
      data = null
      console.log('completeNewPassword err: ', err)
      errorHandler({ message: err.message })
    }
    return { state, data }
  }

  return (
    <FormComp>
      <FormHeader
        smallText="Reset Password"
        isTiny
        mainText="Reset your password"
        leadText="Please enter the secret password you received via mail"
      />
      <FormWrapper>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Box
            sx={{
              top: '',
            }}
          >
            <Input
              name={'oldPassword'}
              labelName="Old Password"
              type="password"
              placeholder="*******"
              value={values.oldPassword.trim()}
              classes={'customInput'}
              required
              onChange={handleChange}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
            />
          </Box>
          <PasswordComp
            formik={formik}
            labelName={'New Password'}
            labelName2={'Confirm new password'}
          />
          <Box
            sx={{
              width: { xs: '100%' },
              height: '36px',
              display: { xs: 'flex' },
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              alignItems: 'center',
              mx: 'auto',
              mt: 4,
            }}
          >
            <Button
              type="submit"
              name={'Next'}
              classes={'blue'}
              disabled={loading || !isValid}
            />
            <Button name={'Back'} classes={'white'} onClick={goBack} />
          </Box>
        </form>
      </FormWrapper>
    </FormComp>
  )
}

export default ResetPassword
