import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  initialAccreditation1,
  initialAccreditation2,
  initialAccreditation3,
  initialAccreditation4,
  initialPersonal,
  initialResidence,
} from 'data/onboardingData'
import {
  onboardingAccreditation1,
  onboardingAccreditation2,
  onboardingAccreditation3,
  onboardingAccreditation4,
  onboardingPersonal,
  onboardingResidence,
  onboardingState,
} from 'interfaces/onboarding'

const initialState: onboardingState = {
  personal: initialPersonal,
  residence: initialResidence,
  accreditation1: initialAccreditation1,
  accreditation2: initialAccreditation2,
  accreditation3: initialAccreditation3,
  accreditation4: initialAccreditation4,
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setPersonal: (
      state,
      action: PayloadAction<{
        data: onboardingPersonal
      }>
    ) => {
      state.personal = action.payload.data
    },
    setResidence: (
      state,
      action: PayloadAction<{
        data: onboardingResidence
      }>
    ) => {
      state.residence = action.payload.data
    },
    setAccreditation1: (
      state,
      action: PayloadAction<{
        data: onboardingAccreditation1
      }>
    ) => {
      state.accreditation1 = action.payload.data
    },
    setAccreditation2: (
      state,
      action: PayloadAction<{
        data: onboardingAccreditation2
      }>
    ) => {
      state.accreditation2 = action.payload.data
    },
    setAccreditation3: (
      state,
      action: PayloadAction<{
        data: onboardingAccreditation3
      }>
    ) => {
      state.accreditation3 = action.payload.data
    },
    setAccreditation4: (
      state,
      action: PayloadAction<{
        data: onboardingAccreditation4
      }>
    ) => {
      state.accreditation4 = action.payload.data
    },
  },
})

export const {
  setPersonal,
  setResidence,
  setAccreditation1,
  setAccreditation2,
  setAccreditation3,
  setAccreditation4,
} = onboardingSlice.actions
export default onboardingSlice.reducer
