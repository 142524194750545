import React, { useRef, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useRoutes } from 'react-router-dom'
import RouteChangeTracker from 'components/Tracking/RouteChangeTracker'
import routes from 'routes/Routes'
import ErrorBoundary from 'shared/ErrorBoundary'
import { Toaster } from 'react-hot-toast'
import { useAppSelector } from 'redux/hooks'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import { IsimpleObject } from 'interfaces/index'
import { HmacSHA256 } from 'crypto-js'
import { HelmetProvider } from 'react-helmet-async'
import './App.scss'
import './font.scss'
import SplashScreen from 'components/SplashScreen'
import { hotjar } from 'react-hotjar'
import ReactGA from 'react-ga'

const HJID = (process.env.REACT_APP_HOTJAR_ID as unknown as number) || 0
const HJSV = (process.env.REACT_APP_HOTJAR_SV as unknown as number) || 0
const TAWKTO_PROPID =
  (process.env.REACT_APP_TAWKTO_PROPID as unknown as string) || ''
const TAWKTO_WIDGID =
  (process.env.REACT_APP_TAWKTO_WIDGID as unknown as string) || 'default'
const TAWKTO_APIKEY =
  (process.env.REACT_APP_TAWKTO_APIKEY as unknown as string) || ''

console.log(TAWKTO_PROPID)
console.log(TAWKTO_WIDGID)
console.log(TAWKTO_APIKEY)

function App() {
  const content = useRoutes(routes)
  const auth = useAppSelector((state) => state.auth)
  const TRACKING_ID =
    (process.env.REACT_APP_GOOGLE_TRACKING_ID as unknown as string) || ''

  TRACKING_ID && ReactGA.initialize(TRACKING_ID)

  HJID && HJSV && hotjar.initialize(HJID, HJSV)

  const tawkMessengerRef = useRef<any>()
  const [tawktoLoaded, setTawktoLoaded] = useState<boolean>(false)

  const onLoad = () => {
    setTawktoLoaded(true)
  }

  const updateUserDetails: (userInfo: IsimpleObject) => void = (userInfo) => {
    tawkMessengerRef?.current &&
      tawkMessengerRef.current?.setAttributes(userInfo, function (error: any) {
        error && console.log('Unable to update ', error)
      })
  }

  useEffect(() => {
    const {
      id,
      firstName,
      lastName,
      email,
      dateOfBirth,
      phoneNumber,
      taxId,
      country,
      city,
      state,
      clientId,
      accountId,
      onboardingStatus,
    } = auth.user

    const userData = {
      hash: HmacSHA256(email, TAWKTO_APIKEY).toString(),
      email,
      phone: phoneNumber,
      userId: id,
      name: { first: firstName, last: lastName },
      dateOfBirth,
      region: state,
      city,
      taxId,
      country,
      clientId,
      accountId,
      onboardingStatus,
    }

    id && email && tawktoLoaded && updateUserDetails(userData)
  }, [auth.user, tawktoLoaded])

  return (
    <>
      <HelmetProvider>
        <RouteChangeTracker />
        <ErrorBoundary>
          <Toaster />
          <TawkMessengerReact
            propertyId={TAWKTO_PROPID}
            widgetId={TAWKTO_WIDGID}
            ref={tawkMessengerRef}
            onLoad={onLoad}
          />
          <div className="App">
            {auth.isInitialized ? content : <SplashScreen />}
          </div>
        </ErrorBoundary>
      </HelmetProvider>
    </>
  )
}

export default Sentry.withProfiler(App)
