import { createSlice } from '@reduxjs/toolkit'
import { ICurrencyFxState } from 'interfaces/currencyFx'

const initialState: ICurrencyFxState = {
  exchangeRate: 0,
  baseCurrency: null,
  amount: '',
  convertedAmount: 0,
  fxTransactionId: null,
}

const currencyFxSlice = createSlice({
  name: 'currencyFx',
  initialState,
  reducers: {
    setExchangeRateData: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setExchangeRateData } = currencyFxSlice.actions
export default currencyFxSlice.reducer
