const Eye = () => (
  <svg
    width="21"
    height="14"
    viewBox="0 0 21 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 0C8.23341 0.00131996 6.0197 0.669712 4.14698 1.91819C2.27426 3.16667 0.82925 4.93743 0 7C0.82925 9.06257 2.27426 10.8333 4.14698 12.0818C6.0197 13.3303 8.23341 13.9987 10.5 14C12.7666 13.9987 14.9803 13.3303 16.853 12.0818C18.7257 10.8333 20.1707 9.06257 21 7C20.1707 4.93743 18.7257 3.16667 16.853 1.91819C14.9803 0.669712 12.7666 0.00131996 10.5 0ZM10.5 11.6667C9.23419 11.6667 8.02023 11.175 7.12517 10.2998C6.23011 9.42466 5.72727 8.23768 5.72727 7C5.72727 5.76232 6.23011 4.57534 7.12517 3.70017C8.02023 2.825 9.23419 2.33333 10.5 2.33333C11.7658 2.33333 12.9798 2.825 13.8748 3.70017C14.7699 4.57534 15.2727 5.76232 15.2727 7C15.2727 8.23768 14.7699 9.42466 13.8748 10.2998C12.9798 11.175 11.7658 11.6667 10.5 11.6667ZM10.5 4.2C9.74052 4.2 9.01214 4.495 8.4751 5.0201C7.93807 5.5452 7.63636 6.25739 7.63636 7C7.63636 7.74261 7.93807 8.4548 8.4751 8.9799C9.01214 9.505 9.74052 9.8 10.5 9.8C11.2595 9.8 11.9879 9.505 12.5249 8.9799C13.0619 8.4548 13.3636 7.74261 13.3636 7C13.3636 6.25739 13.0619 5.5452 12.5249 5.0201C11.9879 4.495 11.2595 4.2 10.5 4.2Z"
      fill="#B2C6E6"
    />
  </svg>
)

export default Eye
