import { Box } from '@mui/material'
import './FormWrapper.scss'

interface Children {
  children: React.ReactNode
}

const FormWrapper = ({ children }: Children) => (
  <Box className="form-wrapper">{children}</Box>
)
export default FormWrapper
