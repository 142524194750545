const EyeClosed = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.89 14.0247L1.17043 0.0915167C1.02393 -0.0305056 0.786556 -0.0305056 0.640061 0.0915167L0.109872 0.533375C-0.0366239 0.655398 -0.0366239 0.85327 0.109872 0.975322L3.21089 3.55956C1.73025 4.53597 0.610952 5.86705 0.0179032 7.40451C-0.00589744 7.46647 -0.00589744 7.53359 0.0179032 7.59555C1.2934 10.9031 4.90313 13.1251 8.99994 13.1251C10.6024 13.1251 12.1432 12.7867 13.5191 12.1498L16.8295 14.9085C16.9759 15.0305 17.2133 15.0305 17.3598 14.9085L17.89 14.4666C18.0365 14.3446 18.0365 14.1467 17.89 14.0247ZM8.99994 11.8751C6.10504 11.8751 3.74997 9.91245 3.74997 7.50004C3.74997 6.55041 4.1247 5.63438 4.7997 4.88355L6.43006 6.24223C6.1602 6.61978 5.99996 7.04629 5.99996 7.50001C5.99996 8.8785 7.34577 10 8.99994 10C9.5444 10 10.0562 9.86646 10.5093 9.6416L12.1396 11.0003C11.2386 11.5628 10.1395 11.8751 8.99994 11.8751Z"
      fill="#B2C6E6"
    />
    <path
      d="M8.68371 5.19366C8.62475 5.31055 8.6566 5.44605 8.76428 5.53576L11.357 7.69642C11.4288 7.75624 11.5248 7.78797 11.6222 7.78797C11.6713 7.78797 11.7207 7.78003 11.7676 7.76357C11.9082 7.71412 11.999 7.59907 11.9972 7.47183C11.9785 6.10248 10.677 5.01791 9.03379 5.00232C8.88438 5.01574 8.74302 5.07647 8.68371 5.19366Z"
      fill="#B2C6E6"
    />
    <path
      d="M6.31467 3.49442C6.4271 3.5884 6.59884 3.61222 6.74168 3.55544C7.45799 3.2698 8.21789 3.12484 9.00011 3.12484C11.895 3.12484 14.2501 5.08745 14.2501 7.49985C14.2501 8.15171 14.0761 8.78497 13.7334 9.3819C13.6649 9.50152 13.6942 9.64405 13.8066 9.73774L15.1063 10.8208C15.1766 10.8794 15.2718 10.9124 15.3714 10.9124C15.3718 10.9124 15.3722 10.9124 15.3722 10.9124C15.4721 10.9121 15.5677 10.8788 15.638 10.8199C16.7037 9.92299 17.5145 8.80788 17.9821 7.59539C18.0059 7.53343 18.0059 7.46631 17.9821 7.40435C16.7066 4.09682 13.0969 1.87482 9.00011 1.87482C7.87585 1.87482 6.771 2.04143 5.71593 2.37044C5.59654 2.40767 5.50791 2.49281 5.48081 2.59687C5.4537 2.70064 5.49216 2.80898 5.58223 2.88404L6.31467 3.49442Z"
      fill="#B2C6E6"
    />
  </svg>
)

export default EyeClosed
