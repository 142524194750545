import './Button.scss'

interface ButtonProps {
  name: string
  onClick?: (e: React.SyntheticEvent) => void
  disabled?: boolean
  classes?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  onSubmit?: () => void
  icon?: React.ReactNode
  expand?: boolean
}

const Button = ({
  name,
  type,
  onClick,
  disabled,
  classes,
  onSubmit,
  icon,
  expand,
}: ButtonProps) => {
  return (
    <button
      className={`button ${disabled ? `disabled ${classes}` : classes} ${
        expand ? 'expand' : ''
      }`}
      type={type || 'submit'}
      onClick={onClick || undefined}
      name={name}
      disabled={disabled}
      onSubmit={onSubmit || undefined}
    >
      {icon}
      <span>{name}</span>
    </button>
  )
}

export default Button
