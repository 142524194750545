/* eslint-disable */
import { GraphQLResult } from '@aws-amplify/api-graphql'
import {
  CreateReferencesMutation,
  GetReferencesQuery,
  GetUserBalancesQuery,
  GetWithdrawalsByUserQuery,
  ReferencesStatus,
  UpdateReferencesMutation,
  UpdateUserMutation,
  GetExchangeRatesQuery,
  CreateFxTransactionsMutation,
  UpdateFxTransactionsMutation,
  GetFxTransactionsQuery,
  FxTransactionsStatus,
} from 'API'
import { API } from 'aws-amplify'
import {
  createFxTransactions,
  createReferences,
  updateFxTransactions,
  updateReferences,
  updateUser,
} from 'graphql/mutations'
import {
  GetExchangeRates,
  getFxTransactions,
  getReferences,
  GetUserBalances,
  getWithdrawalsByUser,
} from 'graphql/queries'
import { IDataResult } from 'interfaces'
import { IStatementDetails, ITransaction } from 'interfaces/transactions'
//import { getCurrentAuthenticatedUser } from 'redux/authSlice'

interface GetAccountTransactionsResult {
  success: boolean
  data: Array<ITransaction>
}

interface GetAccountStatementResult {
  success: boolean
  data: IStatementDetails | null
}

interface IFetchTransactionsResult {
  deposits: Array<any>
  withdrawals: Array<any>
  principal: Array<any>
  interests: Array<any>
}

export const getUserAccountBalances = async (
  uid: string,
  accountId: string
) => {
  let { success, data } = { success: false, data: [] } as IDataResult

  try {
    const balances = (await API.graphql({
      query: GetUserBalances,
      variables: { uid: uid, accountId: accountId },
      authMode: 'API_KEY',
    })) as GraphQLResult<GetUserBalancesQuery>

    success = true
    data = balances.data?.GetUserBalances
  } catch (err) {
    console.log(err)
    success = false
    data = []
  }

  return { success, data }
}

export const getAccountDepositInformation = async (accountId: string) => {
  let { success, data } = { success: false, data: null }

  try {
    const apiName = 'flunaIFServer'
    const path = '/if-account-deposit-info'

    const config = {
      headers: {},
      response: true,
      queryStringParameters: {
        accountId: accountId,
      },
    }
    const result = await API.get(apiName, path, config)
    if (result.status === 200 && result.data.success === true) {
      success = true
      data = result.data.depositInfo
    }
  } catch (err) {
    console.log(err)
    success = false
    data = null
  }

  return { success, data } as any
}

export const getAccountTransactions = async (
  uid: string,
  clientId: string,
  accountId: string
) => {
  let { success, data }: GetAccountTransactionsResult = {
    success: false,
    data: [],
  }

  try {
    const apiName = 'flunaIFServer'
    const path = '/if-transactions-history'

    const config = {
      headers: {},
      response: true,
      queryStringParameters: {
        uid: uid,
        clientId: clientId,
        accountId: accountId,
      },
    }
    const result = await API.get(apiName, path, config)
    const items: IFetchTransactionsResult = result.data.transactions

    const filteredPrincipal = items.principal.filter(
      (x) =>
        x.type === 'Principal' &&
        x.destination === 'Investment Wallet' &&
        x.status.toLowerCase() !== 'cancelled'
    )

    const filteredInterests = items.interests.filter(
      (x) =>
        x.type === 'Interest' &&
        x.destination === 'Wallet' &&
        x.status.toLowerCase() !== 'cancelled'
    )

    const filteredDeposits = items.deposits.filter(
      (x) => x.status.toLowerCase() !== 'cancelled'
    )

    const filteredWithdrawals = items.withdrawals.filter(
      (x) => x.status.toLowerCase() !== 'cancelled'
    )

    const set = filteredDeposits
      .concat(filteredInterests)
      .concat(filteredPrincipal)
      .concat(filteredWithdrawals)

    set.sort((a, b) => {
      const dateA: number = new Date(a.createdAt).getTime()
      const dateB: number = new Date(b.createdAt).getTime()
      return dateB - dateA
    })

    success = true
    data = set
  } catch (err) {
    console.log(err)
    success = false
    data = []
  }

  return { success, data }
}

export const setAccountUniqueReference = async (
  uid: string,
  reference: string,
  amount: number,
  currency: string
) => {
  let { success, data } = { success: false, data: null } as IDataResult

  try {
    const data_ = {
      id: uid,
      reference: reference,
      amount: 0,
      currency: currency || 'USD',
      Status: ReferencesStatus.Pending,
      userID: uid,
      notes: null,
    }

    const references = (await API.graphql({
      query: createReferences,
      variables: { input: data_ },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<CreateReferencesMutation>

    success = true
    data = references.data?.createReferences
  } catch (err) {
    console.log(err)
    success = false
    data = null
  }

  return { success, data }
}

export const saveTransferredAmount = async (
  reference: string,
  uid: string,
  amount: number,
  currency: string,
  status: ReferencesStatus,
  investmentId?: string
) => {
  let { success, data } = { success: false, data: null } as IDataResult

  try {
    const date = new Date().toISOString()
    const info = {
      reference: reference,
      amount: amount,
      currency: currency,
      Status: status,
      userID: uid,
      investmentID: investmentId || null,
      notes: null,
      createdAt: date,
      updatedAt: date,
    }

    const references = (await API.graphql({
      query: createReferences,
      variables: { input: info },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<CreateReferencesMutation>

    success = true
    data = references.data?.createReferences
  } catch (err) {
    console.log(err)
    success = false
    data = null
  }

  return { success, data }
}

export const updateTransferStatus = async (
  id: string,
  status: ReferencesStatus,
  proofOfPaymentUrl?: string
) => {
  let { success, data } = { success: false, data: null } as IDataResult

  try {
    const existingReference = (await API.graphql({
      query: getReferences,
      variables: { id },
      authMode: 'API_KEY',
    })) as GraphQLResult<GetReferencesQuery>

    const existingStatus = existingReference.data?.getReferences?.Status

    if (
      status === ReferencesStatus.Sent &&
      (existingStatus === ReferencesStatus.Sent ||
        existingStatus === ReferencesStatus.Completed)
    ) {
      success = true
      data = false
      return { success, data }
    }

    const info = {
      id: id,
      Status: status,
      updatedAt: new Date().toISOString(),
      ProofOfPaymentUrl: proofOfPaymentUrl || '',
    }

    const reference = (await API.graphql({
      query: updateReferences,
      variables: { input: info },
      authMode: 'API_KEY',
    })) as GraphQLResult<UpdateReferencesMutation>

    success = true
    data = reference.data?.updateReferences
  } catch (err) {
    console.log(err)
    success = false
    data = null
  }

  return { success, data }
}

export const checkIfPendingWithdrawalsExists = async (uid: string) => {
  let state = false
  try {
    const result = (await API.graphql({
      query: getWithdrawalsByUser,
      variables: {
        userID: uid,
        filter: { withdrawalStatus: { eq: 'pending' } },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<GetWithdrawalsByUserQuery>

    const data = result.data?.getWithdrawalsByUser?.items
    if (data === undefined) {
      return state
    }
    if (data?.length > 0) {
      state = true
    }
    return state
  } catch (error) {
    console.log(error)
    return state
  }
}

export const updateUserBankPayoutOptIn = async (
  userID: string,
  option: boolean
) => {
  const payload = {
    id: userID,
    monthlyInterestPayoutsOptIn: option,
  }

  try {
    const userData = (await API.graphql({
      query: updateUser,
      variables: { input: payload },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<UpdateUserMutation>

    return userData.data?.updateUser
  } catch (error) {
    console.log(error)
  }
}

export const getFxRates = async (
  sourceCurrency: string,
  destinationCurrency: string
) => {
  let { success, data } = { success: false, data: null } as IDataResult

  try {
    const response = (await API.graphql({
      query: GetExchangeRates,
      variables: {
        sourceCurrency: sourceCurrency,
        destinationCurrency: destinationCurrency,
      },
      authMode: 'API_KEY',
    })) as GraphQLResult<GetExchangeRatesQuery>

    success = true
    data = response.data?.GetExchangeRates?.exchangeRate
  } catch (err) {
    console.log(err)
    success = false
    data = null
  }

  return { success, data } as any
}

export const saveFxTransaction = async (payload: any) => {
  let { success, data } = { success: false, data: null } as IDataResult

  try {
    const references = (await API.graphql({
      query: createFxTransactions,
      variables: { input: payload },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<CreateFxTransactionsMutation>

    success = true
    data = references.data?.createFxTransactions
  } catch (err) {
    console.log(err)
    success = false
    data = null
  }

  return { success, data }
}

export const updateFxTransactionStatus = async (payload: any) => {
  let { success, data } = { success: false, data: null } as IDataResult

  try {
    const transaction = (await API.graphql({
      query: updateFxTransactions,
      variables: { input: payload },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<UpdateFxTransactionsMutation>

    success = true
    data = transaction.data?.updateFxTransactions
  } catch (err) {
    console.log(err)
    success = false
    data = null
  }

  return { success, data }
}

export const updateFxTransferStatus = async (
  id: string,
  status: FxTransactionsStatus
) => {
  let { success, data } = { success: false, data: null } as IDataResult

  try {
    const existingReferences = (await API.graphql({
      query: getFxTransactions,
      variables: { id: id },
      authMode: 'API_KEY',
    })) as GraphQLResult<GetFxTransactionsQuery>

    const existingStatus = existingReferences.data?.getFxTransactions?.Status

    if (
      status === FxTransactionsStatus.Sent &&
      (existingStatus === FxTransactionsStatus.Sent ||
        existingStatus === FxTransactionsStatus.Completed)
    ) {
      success = true
      data = false
      return { success, data }
    }

    const info = {
      id: id,
      Status: status,
      updatedAt: new Date().toISOString(),
    }

    const transaction = (await API.graphql({
      query: updateFxTransactions,
      variables: { input: info },
      authMode: 'API_KEY',
    })) as GraphQLResult<UpdateFxTransactionsMutation>

    success = true
    data = transaction.data?.updateFxTransactions
  } catch (err) {
    console.log(err)
    success = false
    data = null
  }

  return { success, data }
}

export const getAccountStatementData = async (
  uid: string,
  startDate: string,
  endDate: string,
  currentQuarter: string,
  currentPeriod: string
) => {
  let { success, data }: GetAccountStatementResult = {
    success: false,
    data: null,
  }

  try {
    const apiName = 'flunaIFServer'
    const path = '/account-statement'

    const config = {
      headers: {},
      response: true,
      queryStringParameters: {
        uid: uid,
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString(),
        currentQuarter: currentQuarter,
        currentPeriod: currentPeriod,
      },
    }

    const result = await API.get(apiName, path, config)
    const statementDetails = result.data.data

    success = statementDetails !== null ? true : false
    data = statementDetails
  } catch (err) {
    console.log(err)
    success = false
    data = null
  }

  return { success, data }
}
