import React from 'react'
import { useState } from 'react'

import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'

const AuthGuardStatus = (props: { children: React.ReactElement }) => {
  const { children } = props
  const auth = useAppSelector((state) => state.auth)
  const location = useLocation()
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  )

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname)
    }

    return <Navigate to="/login" />
  }

  if (auth.isAuthenticated && auth.user.onboardingStatus !== 'completed') {
    return <Navigate to="/login" />
  }

  // This is to prevent users from going back to the Onboarding Flow after already completing Onboarding.
  if (
    auth.isAuthenticated &&
    auth.user.kycVerificationStatus !== 'verified' &&
    auth.user.kycVerificationStatus !== 'finished'
  ) {
    if (
      location.pathname !== '/dashboard' &&
      location.pathname !== '/transactions' &&
      location.pathname !== '/settings/profile' &&
      location.pathname !== '/settings/security'
    ) {
      return <Navigate to="/dashboard?vs=false" />
    }
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <>{children}</>
}

export default AuthGuardStatus
