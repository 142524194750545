const Good = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.50001 9.47499L2.02501 5.99999L0.841675 7.17499L5.50001 11.8333L15.5 1.83333L14.325 0.658325L5.50001 9.47499Z"
      fill="#3BB75E"
    />
  </svg>
)

export default Good
