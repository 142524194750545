import AuthenticationLayout from 'layout/authentication/AuthenticationLayout'
import Loading from 'components/Loading'

// Routes
import AuthRoutes from './AuthRoutes'
import GeneralRoutes from './GeneralRoutes'
import AuthGuardStatus from 'auth/AuthGuardStatus'
import GeneralLayoutV2 from 'layout/generalV2/GeneralLayoutV2'

const routes = [
  {
    path: '*',
    element: (
      <Loading>
        <AuthenticationLayout />
      </Loading>
    ),
    children: [...AuthRoutes],
  },
  {
    path: '*',
    element: (
      <AuthGuardStatus>
        <Loading>
          <GeneralLayoutV2 />
        </Loading>
      </AuthGuardStatus>
    ),
    children: [...GeneralRoutes],
  },
]

export default routes
