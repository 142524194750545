import React, { useState, Suspense } from 'react'
import SplashScreen from 'components/SplashScreen'
import { Outlet } from 'react-router-dom'
import { IconButton, Box, Grid } from '@mui/material'
import { CircleDoubleLeft, CircleDoubleRight } from '@icon-park/react'
import flunaLogo from 'assets/Images/fluna-logo_alt.svg'
import MenuLink from 'components/Links/MenuLink'
import ResponsiveAppBarV2 from './GeneralNavBarV2'
import { SettingsNav, navPages } from 'components/navigation'
import SubMenuLink from 'components/Links/SubMenuLink'

const GeneralLayoutV2 = () => {
  const secondaryRoute = ''
  const [opened, setOpened] = useState<boolean>(true)
  const [showing, setShowing] = useState<string>('')

  return (
    <>
      <Grid container>
        <Grid
          item
          md={opened ? 3 : 1}
          lg={opened ? 2 : 1}
          sx={{
            backgroundColor: '#212437',
            color: '#D5D7E3',
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Grid
            data-testid="sidebar"
            sx={{
              backgroundColor: '#212437',
              height: '100vh',
              overflowX: 'hidden',
              position: 'fixed',
              zIndex: '100',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              webkitOverflowScrolling: 'touch',
              display: 'flex',
              flexDirection: 'column',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              width: {
                md: opened ? '25%' : '8.3%',
                lg: opened ? '16.6%' : '8.3%',
              },
            }}
          >
            <Grid
              mt={4}
              mb={opened ? 4 : 2}
              mx={opened ? 2 : 0}
              sx={{ paddingRight: '16px' }}
              container
              direction={opened ? 'row' : 'column'}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                sx={{
                  maxWidth: '8rem',
                  maxHeight: '2.7rem',
                }}
              >
                <img
                  alt="Fluna"
                  src={flunaLogo}
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    minWidth: '96px',
                    height: '100%',
                    backgroundColor: '#fff',
                  }}
                />
              </Box>

              <IconButton
                size="large"
                edge="start"
                aria-label="collapse"
                onClick={() => setOpened(!opened)}
                sx={{ color: '#171721' }}
              >
                {opened ? (
                  <CircleDoubleLeft theme="filled" size="24" fill="#333" />
                ) : (
                  <CircleDoubleRight theme="filled" size="24" fill="#333" />
                )}
              </IconButton>
            </Grid>

            <Box
              sx={{
                height: '100%',
                padding: '16px 0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  color: '#171721',
                  flexDirection: 'column',
                  overflowY: 'scroll',
                  maxHeight: '55vh',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  flex: 1,
                }}
              >
                {navPages.map((page) => (
                  <MenuLink
                    key={page.name}
                    page={page}
                    opened={opened}
                    setShowing={setShowing}
                    allowComing
                    allowNotif
                  />
                ))}
              </Box>

              <Box
                sx={{
                  position: 'relative',
                  bottom: '80px',
                  display: { xs: 'none', md: 'flex' },
                  color: '#171721',
                  flexDirection: 'column',
                }}
              >
                {SettingsNav.map((page) => (
                  <SubMenuLink
                    key={page.name}
                    page={page}
                    opened={opened}
                    setShowing={setShowing}
                    showing={showing}
                    disabled={page.disabled || false}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Suspense fallback={<SplashScreen />}>
          <Grid
            sx={{
              width: '100%',
              display: 'block',
              paddingLeft: {
                md: opened && secondaryRoute ? '16%' : '0',
                lg: opened && secondaryRoute ? '16%' : '0',
              },
            }}
            item
            md={opened ? 9 : 11}
            lg={opened ? 10 : 11}
          >
            <ResponsiveAppBarV2 />

            <Box
              sx={{
                width: '100%',
                height: '100vh',
                display: 'block',
                margin: '0 auto',
                zIndex: '0',
                paddingLeft: {
                  md: !opened && secondaryRoute ? '16%' : '0',
                  lg: !opened && secondaryRoute ? '16%' : '0',
                },
              }}
            >
              <Outlet />
            </Box>
          </Grid>
        </Suspense>
      </Grid>
    </>
  )
}

export default GeneralLayoutV2
