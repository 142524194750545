import Input from 'components/Input'
import { useEffect, useState } from 'react'
import { validatePasswordInput } from 'utils/helpers'
import ValidatePassword from 'components/ValidatePassword'

interface IPasswordComp {
  formik: any
  labelName: string
  labelName2: string
}

const PasswordComp = ({ formik, labelName, labelName2 }: IPasswordComp) => {
  const [isValid, setIsValid] = useState(false)
  const [isMinLength, setIsMinLength] = useState(false)
  const [isUpperCase, setIsUpperCase] = useState(false)
  const [hasUniqueChar, setHasUniqueChar] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(false)

  const { values, errors, touched, handleBlur, handleChange } = formik

  useEffect(() => {
    const { isMinLength, isUpperCase, hasUniqueChar } = validatePasswordInput(
      values.password
    )

    setIsMinLength(isMinLength)
    setIsUpperCase(isUpperCase)
    setHasUniqueChar(hasUniqueChar)

    if (isMinLength && isUpperCase && hasUniqueChar) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }

    if (values.password === values.confirmPassword) {
      setPasswordMatch(true)
    } else {
      setPasswordMatch(false)
    }
  }, [
    values.password,
    values.confirmPassword,
    isMinLength,
    isUpperCase,
    hasUniqueChar,
  ])

  return (
    <>
      <Input
        name="password"
        labelName={labelName}
        type="password"
        placeholder="create a password"
        value={values.password.trim()}
        required
        onChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        dataTestId="password"
      />

      <ValidatePassword
        isMinLength={isMinLength}
        isUpperCase={isUpperCase}
        hasUniqueChar={hasUniqueChar}
        classes={touched['password'] ? '' : !passwordMatch ? '' : 'hide'}
      />

      {isValid && (
        <Input
          name="confirmPassword"
          labelName={labelName2}
          type="password"
          placeholder="re-enter your password"
          value={values.confirmPassword.trim()}
          required
          onChange={handleChange}
          passwordMatch={passwordMatch}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          dataTestId="confirmPassword"
        />
      )}
    </>
  )
}

export default PasswordComp
