interface IInfo {
  w?: string
  h?: string
  fill?: string
}
const Info: React.FC<IInfo> = ({ w, h, fill }: IInfo) => {
  return (
    <svg
      width={w || '12'}
      height={h || '12'}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6665 2.33366L4.33317 2.33366V3.00033H3.6665V2.33366ZM3.6665 3.66699H4.33317L4.33317 5.66699H3.6665L3.6665 3.66699ZM3.99984 0.666992C2.15984 0.666992 0.666504 2.16033 0.666504 4.00033C0.666504 5.84033 2.15984 7.33366 3.99984 7.33366C5.83984 7.33366 7.33317 5.84033 7.33317 4.00033C7.33317 2.16033 5.83984 0.666992 3.99984 0.666992ZM3.99984 6.66699C2.52984 6.66699 1.33317 5.47033 1.33317 4.00033C1.33317 2.53033 2.52984 1.33366 3.99984 1.33366C5.46984 1.33366 6.6665 2.53033 6.6665 4.00033C6.6665 5.47033 5.46984 6.66699 3.99984 6.66699Z"
        fill={fill || '#2563EB'}
      />
    </svg>
  )
}

export default Info
