const Check = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9958 7.34913 18.9408 4.80805 17.0664 2.93361C15.192 1.05916 12.6509 0.00423124 10 0V0ZM14.71 8.71L9.71 13.71C9.52044 13.8959 9.26552 14.0001 9 14.0001C8.73449 14.0001 8.47957 13.8959 8.29 13.71L5.29 10.71C5.19677 10.6168 5.12281 10.5061 5.07234 10.3842C5.02188 10.2624 4.99591 10.1319 4.99591 10C4.99591 9.7337 5.1017 9.4783 5.29 9.29C5.47831 9.1017 5.7337 8.99591 6 8.99591C6.26631 8.99591 6.5217 9.1017 6.71 9.29L9 11.59L13.29 7.29C13.4783 7.1017 13.7337 6.99591 14 6.99591C14.2663 6.99591 14.5217 7.1017 14.71 7.29C14.8983 7.4783 15.0041 7.7337 15.0041 8C15.0041 8.2663 14.8983 8.5217 14.71 8.71V8.71Z"
      fill="#B8C2CC"
    />
  </svg>
)

export default Check
