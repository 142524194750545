import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, Icon, Skeleton, Typography } from '@mui/material'
import NoTransactionsImg from '../../assets/Images/no_transactions.svg'
interface NoDataProps {
  text: string
  altView: boolean
}

const NoData = ({ text, altView }: NoDataProps) => {
  return (
    <React.Fragment>
      {!altView ? (
        <Box
          component={'div'}
          sx={{
            width: '100%',
            height: 'auto',
            padding: '3.5rem 0',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            className="text"
            sx={{
              fontFamily: 'Aeonik-Regular',
              color: '#8083A3',
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            {text}
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            component={'div'}
            sx={{
              position: 'relative',
              mt: 7,
              width: '100%',
              textAlign: 'center',
            }}
          >
            <img src={NoTransactionsImg} alt="no transactions banner" />
            <Typography
              sx={{
                position: 'relative',
                mt: 3,
                color: '#596A7A',
                fontSize: '25px',
                fontWeight: 500,
                fontFamily: 'Aeonik-Regular',
              }}
            >
              No transaction yet
            </Typography>

            <Typography
              sx={{
                position: 'relative',
                mt: 1,
                mb: 4,
                color: '#596A7A',
                fontSize: '16px',
                fontWeight: 300,
                fontFamily: 'Aeonik-Regular',
              }}
            >
              {text}
            </Typography>
          </Box>
        </>
      )}
    </React.Fragment>
  )
}

export interface ITableHeaderLabel {
  label: string
  icon: string | null
}

export interface ITableRows {
  data: Array<any>
  action?: (val: any) => void
  downloadComplete?: boolean | null
  disableAction?: boolean | null
}
interface DataTableProps {
  headerLabels: Array<ITableHeaderLabel>
  isLoading: boolean
  noData: boolean
  noDataText: string
  noFilteredData?: boolean
  noFilteredDataText?: string
  children: any
}

const DataTable = ({
  headerLabels,
  isLoading,
  noData,
  noDataText,
  noFilteredData,
  noFilteredDataText,
  children,
}: DataTableProps) => {
  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        className="tableContainer"
        sx={{
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        {noData && <NoData text={noDataText} altView={false} />}

        {!noData && (
          <Table
            sx={{
              minWidth: 650,
              '.MuiTableCell-root': {
                borderBottom: '1px solid #E9EAEF',
              },
            }}
          >
            <TableHead>
              <TableRow>
                {headerLabels.map((item, index: number) => (
                  <TableCell
                    key={item.label}
                    className={
                      index === headerLabels.length - 1 ? 'lastItem' : ''
                    }
                  >
                    <Typography
                      className="headerTxt"
                      sx={{
                        color: '#8083A3',
                        fontFamily: 'Aeonik-Regular',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      {item.label}{' '}
                      {item.icon !== null && (
                        <Icon
                          sx={{
                            position: 'relative',
                            top: '1px',
                            left: '2px',
                            fontSize: '12px',
                          }}
                          className="icon"
                        >
                          {item.icon}
                        </Icon>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                children
              ) : (
                <>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '.text': {
                        fontFamily: 'Aeonik-Regular',
                        color: '#8083A3',
                        fontSize: '14px',
                        fontWeight: '400',
                      },
                      '.alt': {
                        color: '#171721',
                      },
                      '.indicator': {
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        display: 'inline-block',
                        mr: '5px',
                      },
                      '.pending': {
                        background: '#FFAA22',
                      },
                      '.completed': {
                        background: '#3BB75E',
                      },
                    }}
                  >
                    <TableCell>
                      <Typography>
                        <Skeleton
                          variant="rectangular"
                          height={20}
                          sx={{
                            color: '#B2C6E6',
                            mt: '20px',
                            width: {
                              xs: 100,
                              sm: 150,
                              md: 150,
                            },
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <Skeleton
                          variant="rectangular"
                          height={20}
                          sx={{
                            color: '#B2C6E6',
                            mt: '20px',
                            width: {
                              xs: 100,
                              sm: 150,
                              md: 150,
                            },
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <Skeleton
                          variant="rectangular"
                          height={20}
                          sx={{
                            color: '#B2C6E6',
                            mt: '20px',
                            width: {
                              xs: 100,
                              sm: 150,
                              md: 150,
                            },
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <Skeleton
                          variant="rectangular"
                          height={20}
                          sx={{
                            color: '#B2C6E6',
                            mt: '20px',
                            width: {
                              xs: 100,
                              sm: 150,
                              md: 150,
                            },
                          }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        )}

        {noFilteredData && !noData && (
          <NoData text={noFilteredDataText || ''} altView={true} />
        )}
      </TableContainer>
    </React.Fragment>
  )
}

export default DataTable
