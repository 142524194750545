/* eslint-disable */

import moment from 'moment'
import { Investment } from 'interfaces/investments'
import { countries } from 'mocks/countries'
import { Quarter } from 'interfaces/bank'
import errorHandler from './ErrorHandler'
import { Auth } from 'aws-amplify'

interface IValidatePassword {
  [key: string]: boolean
}
export function validatePasswordInput<T>(value: T): IValidatePassword {
  const isMinLength = typeof value === 'string' && value.length >= 8

  const hasUniqueChar = /[-=+_{}\\:”';<>,/^()!@#$%^&*.?"]/.test(String(value))

  const isUpperCase = /[A-Z]/.test(String(value))

  return { isMinLength, isUpperCase, hasUniqueChar }
}

export const handleCopyToClipboard = (textToCopy: string) => {
  navigator.clipboard.writeText(textToCopy)
}

export const formatTaxID = (value: any) => {
  let inputValue = value?.split('-').join('')

  if (inputValue?.length > 0) {
    inputValue = inputValue.match(new RegExp('.{1,4}', 'g')).join('-')
  }

  // return value = inputValue
  return value
}

export const formatAmount = (amount: number) => {
  const formattedAmount = amount.toFixed(2)
  const parts = formattedAmount.split('.')
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const decimalPart = parts[1]
  return `${integerPart}.${decimalPart}`
}

export const calculateInterest = (
  principal: number,
  annualInterestRate: number,
  duration: number
) => {
  return Number(
    Number(principal * (annualInterestRate * 0.01)) * ((duration * 30) / 365)
  )
    .toFixed(2)
    .toString()
}

export const calculateTotalBalance = (investment_: Investment) => {
  let total = 0
  const principal = investment_.principleAmount || 0
  const paymentsTotal =
    investment_.payments?.items
      .filter(
        (payment) =>
          payment.paymentCategory === 'Interest' && payment.Status === 'Paid'
      )
      .reduce((total, payment: any) => total + payment?.amount, 0) || 0
  total = principal + paymentsTotal
  return total
}

export function sentenceCase(str: string) {
  return str.toLowerCase().replace(/(^|\s)\S/g, (L) => L.toUpperCase())
}

export const validateEmail = (email: any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const generateUniqueRef = (accountId: string, clientId: string) => {
  const a: string = accountId
  const b: string = clientId
  const ref =
    a.slice(a.length - 5, a.length - 1) +
    '-' +
    b.slice(b.length - 5, b.length - 1)
  return ref
}

export const getCountryCode = (country: string) => {
  let code = null
  const list = countries
  const country_ = list.find((x) => {
    return x.label === country
  })

  if (country_ === undefined || country === null) {
    code = 'US'
    return code
  }
  code = country_.code
  return code
}

export const filterStatesWithoutIsland = (states: any[]) => {
  return states.filter((state) => {
    return state.label && !state.label.includes('Island')
  })
}

export const stripSpaces = (item: string) => {
  return item.replace(/\s+/g, '')
}

export const stringToNumberStr = (item: string) => {
  return item?.replace(/,/g, '')
}

export const formatNumber = (value: string) => {
  const parts = value.split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const removeLeadingZeros = (value: string) => {
  if (value.startsWith('0') && value.length > 1 && value[1] !== '.') {
    return value.replace(/^0+/, '')
  }
  return value
}

export const getDaysLeftToFund = (maxDays: number, date: string) => {
  const createdAt = new Date(moment(date).format('MMM D, YYYY'))
  const today = new Date().getTime()
  const difference = today - createdAt.getTime()
  const daysDifference = Math.floor(difference / (1000 * 3600 * 24))
  return maxDays - daysDifference
}

export const getCurrencySymbol = (currency: string) => {
  let symbol: string | null = null

  if (currency === 'USD') {
    symbol = '$'
  }

  if (currency === 'NGN') {
    symbol = '₦'
  }

  if (currency === 'GHS') {
    symbol = '₵'
  }

  return symbol
}

export const getEndDate = (startDate: Date, tenorDays: number) => {
  const endDate = new Date(startDate.setDate(startDate.getDate() + tenorDays))
  return endDate
}

export const downloadBlob = (blob: Blob, name: string, ext: string) => {
  const fileBlob = blob

  const url = URL.createObjectURL(fileBlob)

  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = url
  a.download = `${name}.${ext}`
  a.click()
  window.URL.revokeObjectURL(url)
}

export const formatDate = (date: Date): string => {
  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })

  let suffix = 'th'
  if (day < 11 || day > 13) {
    switch (day % 10) {
      case 1:
        suffix = 'st'
        break
      case 2:
        suffix = 'nd'
        break
      case 3:
        suffix = 'rd'
        break
    }
  }

  return `${month} ${day}${suffix}, ${date.getFullYear()}`
}

export const getPastQuarters = (
  startDateString: string,
  endDateString: string
): Quarter[] => {
  const quarters = ['Q1', 'Q2', 'Q3', 'Q4']
  const endMonths = [3, 6, 9, 12]
  const quartersArray: Quarter[] = []

  const currentDate = new Date(endDateString)
  const cutoffStartDate = startDateString
    ? new Date(startDateString)
    : new Date(2023, 3, 1)

  const startYear = cutoffStartDate.getFullYear()
  const endYear = currentDate.getFullYear()

  for (let year = startYear; year <= endYear; year++) {
    for (let index = 0; index < quarters.length; index++) {
      const quarter = quarters[index]
      const quarterEndMonth = endMonths[index]
      const quarterStartMonth = quarterEndMonth - 2
      const startDate = new Date(year, quarterStartMonth - 1, 1)
      const endDate = new Date(
        year,
        quarterEndMonth - 1,
        new Date(year, quarterEndMonth, 0).getDate()
      )

      if (endDate < cutoffStartDate || startDate > currentDate) continue

      quartersArray.push({
        label: `${quarter} ${year}`,
        period: `${formatDate(startDate)} - ${formatDate(endDate)}`,
        startDate: startDate,
        endDate: endDate,
      })
    }
  }

  quartersArray.sort((a, b) => b.endDate.getTime() - a.endDate.getTime())
  return quartersArray
}

export const noUserHelper = async (error: Error) => {
  if (error.message === 'No current user') {
    errorHandler({
      message: 'Oops! You are currently logged out',
    })
    await Auth.signOut()
    window.location.reload()
  }
}

export const isDevelopment = () => {
  const isDev = process.env.REACT_APP_NODE_ENV === 'development'
  console.log('isDevelopment', isDev)
  return isDev
}

export const convertFormikValuesToArray = (values: any) => {
  return Object.keys(values).map((key) => ({
    label: key,
    value: values[key],
  }))
}

export const extractAccountType = (accountType: string) => {
  if (accountType.includes('Personal')) {
    return accountType.replace('Personal', 'Personal ').split(' ')[1]
  } else if (accountType.includes('Business')) {
    return accountType.replace('Business', 'Business ').split(' ')[1]
  }
  return accountType
}

export const sortArrayByField = (
  arr: any[],
  sortField: string,
  order: 'asc' | 'desc'
): any[] => {
  return [...arr].sort((a: any, b: any) => {
    const dateA: number = new Date(a[sortField]).getTime()
    const dateB: number = new Date(b[sortField]).getTime()
    return order === 'desc' ? dateB - dateA : dateA - dateB
  })
}
