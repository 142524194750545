import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Button from '../../components/Button'
import flunaLogo from 'assets/Images/fluna-logo.svg'

import { useNavigate } from 'react-router'
import { RootState } from 'redux/store'
import { useAppSelector } from 'redux/hooks'
import { OnboardingStatus } from 'API'

const pages = ['How it works', 'FAQs']

const ResponsiveAppBar = () => {
  const [showLogin, setShowLogin] = React.useState<boolean>(false)

  const user = useAppSelector((state: RootState) => state.auth.user)

  const navigate = useNavigate()

  const showLoginBtn = ['']

  const path = window.location.pathname.split('/')[1]

  React.useEffect(() => {
    if (showLoginBtn.includes(path)) {
      setShowLogin(true)
    } else {
      setShowLogin(false)
    }
  }, [path])

  return (
    <AppBar position="static" sx={{ backgroundColor: '#17224D' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Aeonik-Regular',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Box
              component="a"
              href={
                user.onboardingStatus === OnboardingStatus.pending
                  ? '/login'
                  : user?.firstName && user?.country === 'United States'
                  ? '/dashboard'
                  : '/login'
              }
              sx={{
                width: {
                  xs: '100px',
                  md: '105px',
                },
                mr: 2,
                ml: { md: '20px', lg: '50px' },
                display: { xs: 'none', sm: 'flex', md: 'flex' },
                borderRadius: '0px',
                '.flunalogo': {
                  objectFit: 'cover',
                  width: '100%',
                  backgroundColor: '#fff',
                },
                py: 3,
              }}
            >
              <img className="flunalogo" src={flunaLogo} alt="..fluna" />
            </Box>
          </Typography>
          <Typography
            variant="h5"
            noWrap
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'Aeonik-Regular',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Box
              component="a"
              href={
                user.onboardingStatus === OnboardingStatus.pending
                  ? '/login'
                  : user?.firstName && user?.country === 'United States'
                  ? '/dashboard'
                  : '/login'
              }
              sx={{
                width: {
                  xs: '100px',
                  md: '105px',
                },
                ml: { md: '20px', lg: '50px' },
                display: { xs: 'flex', sm: 'flex', md: 'flex' },
                borderRadius: '0px',
                '.flunalogo': {
                  objectFit: 'cover',
                  width: '100%',
                  backgroundColor: '#fff',
                },
              }}
            >
              <img className="flunalogo" src={flunaLogo} alt="fluna logo" />
            </Box>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Box
                key={page}
                sx={{
                  my: 2,
                  mr: 2,
                  color: '#86929D',
                  display: 'block',
                  textTransform: 'none',
                  cursor: 'pointer',
                }}
              ></Box>
            ))}
          </Box>

          {showLogin && (
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: 'none', md: 'flex', alignItems: 'center' },
                width: '307px',
                py: 3,
                color: '#fff',
              }}
            >
              <Box
                sx={{ cursor: 'pointer', mr: 4 }}
                onClick={() => navigate('/login')}
              >
                Login
              </Box>
              <Button
                name="Get Started"
                classes={'register'}
                onClick={() => navigate('/signup')}
              />
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
