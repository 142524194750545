import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import CustomLink from './CustomLink'
import { Box, Tooltip, useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

type Navs = {
  name: string
  navigation: string
  element?: any
  notif: number
  coming: boolean
  children?: any[]
}

interface SubMenuProps {
  page: Navs
  opened: boolean
  disabled?: boolean
  setShowing: React.Dispatch<React.SetStateAction<string>>
  showing: string
}

function SubMenuLink({
  page,
  opened,
  disabled,
  showing,
  setShowing,
}: SubMenuProps) {
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  })

  useEffect(() => {
    if (location.pathname.split('/')[1] === page.name.toLowerCase()) {
      setShowing(page.name)
    }
  }, [])

  return (
    <>
      <Box
        data-testid="sub-menu-link"
        sx={{
          color: '#171721',
          cursor: 'pointer',
          padding: '8px 18px',
          margin: '4px 0px',
          fontSize: {
            xs: '16px',
            sm: '16px',
            md: '1rem',
          },
          fontWeight: '400',
          display: 'flex',
          alignitems: 'center',
          justifyContent: opened ? 'space-between' : 'center',
          borderLeft:
            location.pathname.split('/')[1] === page.name.toLowerCase() &&
            !page.children
              ? '4px solid #007FFF'
              : '4px solid #212437',
        }}
        key={page.name}
      >
        <CustomLink
          to={page.navigation}
          disableMatch={!isMobile ? true : false}
          disabled={disabled}
          onClick={() => setShowing(page.name)}
        >
          <Box
            alignItems="center"
            mr="1rem"
            fontSize={opened ? '1.2em' : '1.4em'}
            display={{ xs: 'inline-block', sm: 'inline-block', md: 'block' }}
          >
            {opened ? (
              page.element
            ) : (
              <Tooltip title={`${page.name}`} placement="right-start">
                {page.element}
              </Tooltip>
            )}
          </Box>
          {opened ? page.name : ''}

          {opened && page.coming ? (
            <Box
              sx={{
                backgroundColor: '#3E7EFF',
                color: '#fff',
                borderRadius: '4px',
                margin: '1px 2px',
                padding: '1px 4px',
                fontSize: '0.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Coming soon
            </Box>
          ) : (
            ''
          )}
        </CustomLink>

        {opened &&
        page.children
          ?.map((e) => e.notif)
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          ) ? (
          <Box
            sx={{
              backgroundColor: '#3E7EFF',
              color: '#fff',
              borderRadius: '50%',
              padding: '1px 6px 1px 6px',
              fontSize: {
                xs: '10px',
                sm: '0.6rem',
                md: '0.6rem',
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: {
                xs: '5px',
                sm: '5px',
                md: '5px',
              },
              width: {
                xs: '20px',
                sm: '25px',
                md: '25px',
              },
              height: {
                xs: '20px',
                sm: '25px',
                md: '25px',
              },
            }}
          >
            {page.children
              ?.map((e) => e.notif)
              .reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                0
              )}
          </Box>
        ) : (
          ''
        )}
      </Box>

      <Box
        sx={{
          display: `${showing === page.name ? 'flex' : 'none'}`,
          flexDirection: 'column',
        }}
      >
        {page?.children?.map((e) => {
          const matchSub = e.navigation === location.pathname ? true : false

          return (
            <Box
              key={e.name}
              sx={{
                color: '#171721',
                cursor: 'pointer',
                padding: {
                  xs: '0.5rem 0px 0.5rem 1.5rem',
                  sm: '0.5rem 0px 0.5rem 1.5rem',
                  md: '0.5rem 0px 0.5rem 3rem',
                },
                fontSize: '0.9rem',
                fontWeight: '400',
                display: 'flex',
                alignitems: 'center',
                justifyContent: opened ? 'space-between' : 'center',
                borderLeft: matchSub
                  ? '4px solid #007FFF'
                  : '4px solid #212437',
              }}
            >
              <CustomLink to={e.navigation}>
                <Box
                  alignItems="center"
                  mr="1rem"
                  fontSize={opened ? '1.2em' : '1.4em'}
                  display={'inline-block'}
                >
                  {opened ? (
                    e.element
                  ) : (
                    <Tooltip title={`${e.name}`} placement="right">
                      {e.element}
                    </Tooltip>
                  )}
                </Box>
                {opened ? e.name : ''}

                {opened && e.coming ? (
                  <Box
                    sx={{
                      backgroundColor: '#3E7EFF',
                      color: '#fff',
                      borderRadius: '4px',
                      margin: '1px 2px',
                      padding: '1px 4px',
                      fontSize: '0.5rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Coming soon
                  </Box>
                ) : (
                  ''
                )}
              </CustomLink>

              {opened && e.notif ? (
                <Box
                  sx={{
                    backgroundColor: '#3E7EFF',
                    color: '#fff',
                    borderRadius: '50%',
                    padding: '1px 6px',
                    fontSize: {
                      xs: '10px',
                      sm: '0.6rem',
                      md: '0.6rem',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: {
                      xs: '5px',
                      sm: '5px',
                      md: '5px',
                    },
                    mr: '20px',
                    width: {
                      xs: '20px',
                      sm: '25px',
                      md: '25px',
                    },
                    height: {
                      xs: '20px',
                      sm: '25px',
                      md: '25px',
                    },
                  }}
                >
                  {e.notif}
                </Box>
              ) : (
                ''
              )}
            </Box>
          )
        })}
      </Box>
    </>
  )
}

export default SubMenuLink
