import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import flunaLogo from 'assets/Images/flunawhitelogo.png'
import MenuLink from 'components/Links/MenuLink'
import { SettingsNav, navPages } from 'components/navigation'

interface DrawerProps {
  openDrawer: boolean
  setOpenDrawer: (item: boolean) => void
}

const GeneralMobileSideNav = ({ openDrawer, setOpenDrawer }: DrawerProps) => {
  const opened = true
  const [showing, setShowing] = React.useState<string>('')

  const handleCloseDrawer = () => {
    setOpenDrawer(false)
  }

  React.useEffect(() => {
    setOpenDrawer(openDrawer)
    console.log(showing)
  }, [openDrawer])
  const list = () => (
    <>
      <Box
        sx={{
          width: 250,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#212437',
        }}
        role="presentation"
        onClick={() => setOpenDrawer(false)}
        onKeyDown={() => setOpenDrawer(false)}
        data-testid="GeneralMobileSidebar"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-left',
            alignItems: 'center',
            width: '100%',
            pb: '4.875rem',
          }}
        >
          <Box
            sx={{
              pt: 3,
              pb: 3,
              ml: '20px',
              maxWidth: '7.8rem',
              maxHeight: '2.7rem',
            }}
          >
            <img
              alt="Fluna"
              src={flunaLogo}
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            />
          </Box>
        </Box>

        {navPages.map((page) => (
          <Typography
            onClick={handleCloseDrawer}
            component={'div'}
            key={page.name}
            sx={{
              color: '#171721',
              cursor: 'pointer',
              padding: '15px 0px',
              fontSize: '16px',
              fontWeight: '700',
            }}
          >
            <MenuLink
              key={page.name}
              page={page}
              opened={true}
              setShowing={setShowing}
              allowComing
              allowNotif
            />
          </Typography>
        ))}
      </Box>
      <Box
        sx={{ backgroundColor: '#212437', border: 'none', mt: '-5px' }}
        onClick={() => setOpenDrawer(false)}
        onKeyDown={() => setOpenDrawer(false)}
      >
        {SettingsNav.map((page) => (
          <MenuLink
            key={page.name}
            page={page}
            opened={opened}
            setShowing={setShowing}
            disabled={page.disabled || false}
            allowComing
            allowNotif
          />
        ))}
      </Box>
    </>
  )

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {list()}
      </Drawer>
    </React.Fragment>
  )
}

export default GeneralMobileSideNav
