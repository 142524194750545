import { GraphQLResult } from '@aws-amplify/api'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { API, Auth } from 'aws-amplify'
import { getUser } from 'graphql/queries'
import { IProfile } from 'interfaces/profile'
import { MixPanel } from 'services/mixpanel'
import { AuthState, ChallengeResponse } from 'interfaces/authState'
import ErrorHandler from 'utils/ErrorHandler'
import { LoginPayload } from 'interfaces/User'
import {
  GetUserQuery,
  UpdateUserInput,
  SendOTPTokenQuery,
  UpdateUserMutation,
  VerifyOTPTokenQuery,
  InitiateWithdrawalRequestQuery,
} from 'API'
import * as mutations from 'graphql/mutations'
import * as queries from 'graphql/queries'
import store from './store'
import SuccessHandler from 'utils/SuccessHandler'
import { BankAccountTypes, IAccountDetails } from 'interfaces/bank'

const initialState: AuthState = {
  user: {},
  loginData: {},
  loginReturn: {},
  loading: false,
  isInitialized: false,
  isAuthenticated: false,
  status: '',
  twoFaStatus: '',
  error: '',
}

export const getUserData = async (id: string) => {
  const userData = (await API.graphql({
    query: getUser,
    variables: { id: id },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  })) as GraphQLResult<GetUserQuery>
  MixPanel.people.set({
    $first_name: `${userData.data?.getUser?.firstName}`,
    $last_name: `${userData.data?.getUser?.lastName}`,
    $email: `${userData.data?.getUser?.email}`,
  })
  return userData?.data?.getUser
}

export const getCurrentAuthenticatedUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()

    const userData = await getUserData(user.attributes.sub)
    console.log('userData', userData)
    store.dispatch(
      setCurrentUser({
        attributes: userData || {},
        isAuthenticated: true,
      })
    )

    return user
  } catch (err) {
    return store.dispatch(
      setCurrentUser({ attributes: {}, isAuthenticated: false })
    )
  }
}

export const updateUserInfo = async (payload: IProfile) => {
  try {
    const user = (await API.graphql({
      query: mutations.updateUser,
      variables: { input: payload },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<UpdateUserMutation>
    const cognitoUserDetails = await Auth.currentAuthenticatedUser()

    await Auth.updateUserAttributes(cognitoUserDetails, {
      family_name: payload.lastName,
      name: payload.firstName,
      phone_number: payload.phoneNumber,
    })
    SuccessHandler({
      message: 'Profile updated Successfully!',
    })

    store.dispatch(
      setCurrentUser({
        attributes: user.data?.updateUser || {},
        isAuthenticated: true,
      })
    )
    return user.data?.updateUser?.id
  } catch (err: any) {
    if (err.data?.updateUser) {
      return store.dispatch(
        setCurrentUser({
          attributes: err.data?.updateUser || {},
          isAuthenticated: true,
        })
      )
    } else {
      if (err.message) {
        ErrorHandler(err)
      } else {
        ErrorHandler()
      }
    }
  }
}

export const updateUserBankAccountInfo = async (
  payload: IAccountDetails,
  accountType: BankAccountTypes
) => {
  try {
    const bankAccountPayoutUSD = {
      accountName: payload.accountName,
      accountType: payload.accountType,
      accountNumber: payload.accountNumber,
      achRoutingNumber: payload.routingNumber,
      wireRoutingNumber: payload.routingNumber,
      bankName: payload.bankName,
      iban: payload.ibanCode,
      swiftCode: payload.swiftCode?.trim(),
      country: payload.country,
      currency: 'USD',
      bankAddress: payload.bankAddress,
      bankPostalCode: payload.bankPostalCode,
      recipientAddress: payload.personalAddress,
      memo: payload.memo,
    }

    const bankAccountPayoutNGN = {
      accountName: payload.accountName,
      accountType: payload.accountType,
      accountNumber: payload.accountNumber,
      achRoutingNumber: payload.routingNumber,
      bankName: payload.bankName,
      iban: payload.ibanCode,
      country: payload.country,
      currency: 'NGN',
      bankAddress: payload.bankAddress,
      bankPostalCode: payload.bankPostalCode,
      recipientAddress: payload.personalAddress,
      memo: payload.memo,
    }

    const user = await getCurrentAuthenticatedUser()
    const userId = user.attributes.sub

    let params = {}
    if (accountType === BankAccountTypes.USD) {
      params = { bankAccountPayoutUSD: bankAccountPayoutUSD }
    }

    if (accountType === BankAccountTypes.NGN) {
      params = { bankAccountPayoutNGN: bankAccountPayoutNGN }
    }

    const userData: any = (await API.graphql({
      query: mutations.updateUser,
      variables: { input: { id: userId, ...params } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as GraphQLResult<UpdateUserInput>

    SuccessHandler({
      message: 'Changes have been saved.',
    })
    store.dispatch(
      setCurrentUser({
        attributes: userData.data.updateUser || {},
        isAuthenticated: true,
      })
    )
    return userData?.data
  } catch (err: any) {
    console.log(err)
    if (err.data?.updateUser) {
      return store.dispatch(
        setCurrentUser({
          attributes: err.data?.updateUser || {},
          isAuthenticated: true,
        })
      )
    } else {
      if (err.message) {
        ErrorHandler(err)
      } else {
        ErrorHandler()
      }
    }
  }
}

export const sendOTPCode = async (
  userInfo: {
    id: string
    name: string
    email: string
  },
  nameOfAction?: string
) => {
  try {
    const tokenResponse = (await API.graphql({
      query: queries.sendOTPToken,
      variables: {
        userInfo,
        nameOfAction,
      },
      authMode: 'API_KEY',
    })) as GraphQLResult<SendOTPTokenQuery>

    return tokenResponse
  } catch (err: any) {
    const errMessage = (e: any) => e?.message || e || 'Something went wrong'

    if (
      err?.errors &&
      typeof err.errors === 'object' &&
      err.errors.length > 0
    ) {
      err.errors.map((e: any) => ErrorHandler({ message: errMessage(e) }))
    } else {
      ErrorHandler({ message: errMessage(err) })
    }

    return
  }
}

export const initiateWithdrawal = async (
  userInfo: {
    id: string
    name: string
    email: string
  },
  amount: number,
  currency: string
) => {
  try {
    const tokenResponse = (await API.graphql({
      query: queries.initiateWithdrawalRequest,
      variables: {
        userInfo,
        amount,
        currency,
      },
      authMode: 'API_KEY',
    })) as GraphQLResult<InitiateWithdrawalRequestQuery>

    return tokenResponse
  } catch (err: any) {
    const errMessage = (e: any) => e?.message || e || 'Something went wrong'

    if (
      err?.errors &&
      typeof err.errors === 'object' &&
      err.errors.length > 0
    ) {
      err.errors.map((e: any) => ErrorHandler({ message: errMessage(e) }))
    } else {
      ErrorHandler({ message: errMessage(err) })
    }

    return
  }
}

export const verifyOTPCode = async (userInfo: { id: string }, code: string) => {
  try {
    const tokenResponse = (await API.graphql({
      query: queries.verifyOTPToken,
      variables: {
        userInfo,
        token: code,
      },
      authMode: 'API_KEY',
    })) as GraphQLResult<VerifyOTPTokenQuery>

    return tokenResponse
  } catch (err: any) {
    const errMessage = (e: any) => e?.message || e || 'Something went wrong'

    if (
      err?.errors &&
      typeof err.errors === 'object' &&
      err.errors.length > 0
    ) {
      err.errors.map((e: any) => ErrorHandler({ message: errMessage(e) }))
    } else {
      ErrorHandler({ message: errMessage(err) })
    }

    return
  }
}

export const logout = async () => {
  // localStorage.clear()
  await Auth.signOut()
  MixPanel.signout()
  return store.dispatch(setLogout())
}

export const resendSignUpCode = async (username: string) => {
  await Auth.resendSignUp(username)
}

export const confirmAuthentication = async (
  payload: { [key: string]: any },
  code: string
) => {
  try {
    await Auth.sendCustomChallengeAnswer(payload, code)

    const user = await getCurrentAuthenticatedUser()

    if (user.payload && !user.payload.isAuthenticated) {
      ErrorHandler({
        message: 'The verification code has expired, please resend a new one.',
      })
    } else {
      const userId = user.attributes.sub
      MixPanel.identify(userId as string)
      store.dispatch(setTwoFaSuccess({ status: 'success' }))
    }
  } catch (err) {
    console.log('err', err)
  }
}

export const login = createAsyncThunk(
  'auth/login',
  async (payload: LoginPayload) => {
    try {
      const { email, password } = payload

      const data = await Auth.signIn(email.trim(), password)

      if (data.challengeName === ChallengeResponse.CUSTOM_CHALLENGE) {
        SuccessHandler({ message: 'Code sent successfully' })
      }
      return { loginData: payload, loginReturn: data }
    } catch (err: any) {
      if (err.message) {
        console.log({ err })

        ErrorHandler(err)
      } else {
        ErrorHandler()
      }

      throw err
    }
  }
)
// Then, handle actions in your reducers:
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (
      state,
      action: PayloadAction<{
        attributes: { [key: string]: any }
        isAuthenticated: boolean
      }>
    ) => {
      state.user = action.payload.attributes
      state.isAuthenticated = action.payload.isAuthenticated
      state.isInitialized = true
    },
    setAuthSuccess: (state, action: PayloadAction<{ status: string }>) => {
      state.status = action.payload.status
    },
    setTwoFaSuccess: (state, action: PayloadAction<{ status: string }>) => {
      state.twoFaStatus = action.payload.status
    },
    setLogout: (state) => {
      state.isAuthenticated = false
      state.user = {}
    },
    setLoginReturn: (state, action: PayloadAction<any>) => {
      state.loginReturn = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.status = 'loading'
      state.loading = true
    })

    builder.addCase(login.fulfilled, (state, action) => {
      state.loginData = action.payload.loginData
      state.loginReturn = action.payload.loginReturn
      state.isAuthenticated = true
      state.loading = false
      state.status = 'success'
    })

    builder.addCase(login.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.code
      state.loading = false
    })
  },
})

export const {
  setCurrentUser,
  setAuthSuccess,
  setLogout,
  setTwoFaSuccess,
  setLoginReturn,
} = authSlice.actions

export default authSlice.reducer
