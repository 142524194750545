import { KycVerificationStatus } from 'API'

export const initialPersonal = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  country: '',
  taxID: '',
  phoneNumber: '',
}

export const initialResidence = {
  country: '',
  state: '',
  city: '',
  address: '',
  postalCode: '',
  kycVerificationStatus: KycVerificationStatus.notStarted,
}
export const initialAccreditation1 = {
  annualIncome: '',
  netWorth: '',
  spouseIncluded: '',
}
export const initialAccreditation2 = {
  accreditedInvestor: '',
}

export const initialAccreditation3 = {
  accreditationStatus: '',
}

export const initialAccreditation4 = {
  legalAgreements: false,
}
