import { createSlice } from '@reduxjs/toolkit'
import { IBankAccountsSliceState } from 'interfaces/bank'

const initialState: IBankAccountsSliceState = {
  bankAccountType: null,
  isUpdateState: false,
}

const bankAccountsSlice = createSlice({
  name: 'bankAccounts',
  initialState,
  reducers: {
    setBankAccountType: (state, action) => {
      const type = action.payload.bankAccountType
      state.bankAccountType = type
    },
    setUpdateState: (state, action) => {
      const isUpdate = action.payload.isUpdateState
      state.isUpdateState = isUpdate
    },
  },
})

export const { setBankAccountType, setUpdateState } = bankAccountsSlice.actions
export default bankAccountsSlice.reducer
