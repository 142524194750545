import { configureStore } from '@reduxjs/toolkit'
import dashboardSlice from './dashboardSlice'
import investmentSlice from './investmentSlice'
import authSlice from './authSlice'
import onboardingSlice from './onboardingSlice'
import accountBalancesSlice from './accountBalancesSlice'
import loanbookSlice from './loanbookSlice'
import currencyFxSlice from './currencyFxSlice'
import fundingSlice from './fundingSlice'
import bankAccountsSlice from './bankAccountsSlice'

export const store = configureStore({
  reducer: {
    dashboardSlice: dashboardSlice,
    investmentSlice: investmentSlice,
    auth: authSlice,
    balances: accountBalancesSlice,
    onboarding: onboardingSlice,
    loanbookSlice: loanbookSlice,
    funding: fundingSlice,
    currencyFx: currencyFxSlice,
    bankAccounts: bankAccountsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
