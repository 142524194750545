import { Outlet } from 'react-router-dom'

import AuthNavBar from './AuthenticationNavBar'

const AuthenticationLayout = () => {
  return (
    <div>
      <AuthNavBar />
      <Outlet />
    </div>
  )
}

export default AuthenticationLayout
