import React, { PropsWithChildren } from 'react'
import * as Sentry from '@sentry/browser'

type IProps = PropsWithChildren<{
  [index: string]: any
  children: React.ReactNode
}>

interface IState {
  hasError?: boolean
  eventId: any
}

export default class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = { eventId: null }
  }
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (error.toString().indexOf('ChunkLoadError') > -1) {
      window.location.reload()
    } else {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <div>
          <h1>Something went wrong</h1>
          <button
            onClick={() =>
              Sentry.showReportDialog({
                eventId: this.state.eventId,
              })
            }
          >
            Report feedback
          </button>
        </div>
      )
    }

    //when there's not an error, render children untouched
    return this.props.children
  }
}
