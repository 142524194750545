import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Amplify, Auth } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './redux/store'
import AuthProvider from 'auth/AuthProvider'
import { Provider } from 'react-redux'
import awsExports from './aws-exports'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'metamap-button':
        | React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLElement>,
            HTMLElement
          >
        | any
    }
  }
}

Amplify.configure(awsExports)
Auth.configure({
  authenticationFlowType: 'CUSTOM_AUTH',
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
