import AccountStatements from 'pages/accountStatements'
import React, { lazy } from 'react'

// Authentication pages
export const DashboardV2 = lazy(() => import('pages/dashboardV2'))
export const Loanbook = lazy(() => import('pages/loanbook'))
export const Withdraw = lazy(() => import('pages/withdraw'))
export const Refer = lazy(() => import('pages/Referral'))
export const TransactionsV2 = lazy(() => import('pages/transactionsV2'))
export const SettingsV2 = lazy(() => import('pages/settingsV2'))
export const AddBank = lazy(() => import('pages/settingsV2/AddBank/AddBank'))
export const InvestmentV2 = lazy(() => import('pages/investmentV2'))

//CHILDREN ARRAY
const GeneralRoutes = [
  {
    path: 'dashboard',
    element: <DashboardV2 />,
  },
  {
    path: 'dashboard/:id',
    element: <DashboardV2 />,
  },
  {
    path: 'loanbook',
    element: <Loanbook />,
  },
  {
    path: 'refer',
    element: <Refer />,
  },
  {
    path: 'transactions',
    element: <TransactionsV2 />,
  },
  { path: 'settings/:page', element: <SettingsV2 /> },
  {
    path: 'withdraw/*',
    element: <Withdraw />,
  },
  {
    path: 'add-bank',
    element: <AddBank />,
  },
  {
    path: 'investments',
    element: <InvestmentV2 />,
  },
  {
    path: 'account-statement',
    element: <AccountStatements />,
  },
]

export default GeneralRoutes
