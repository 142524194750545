const Uncheck = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 9C2.79086 9 1 7.20914 1 5H-1C-1 8.31371 1.68629 11 5 11V9ZM9 5C9 7.20914 7.20914 9 5 9V11C8.31371 11 11 8.31371 11 5H9ZM5 1C7.20914 1 9 2.79086 9 5H11C11 1.68629 8.31371 -1 5 -1V1ZM5 -1C1.68629 -1 -1 1.68629 -1 5H1C1 2.79086 2.79086 1 5 1V-1Z"
      fill="#B2C6E6"
    />
  </svg>
)

export default Uncheck
