import { WalletBalances } from './dashboard'

export enum TransactionType {
  Deposit = 'Deposit',
  Investment = 'Investment',
  Principal = 'Principal',
  InterestPayout = 'InterestPayout',
  Interest = 'Interest',
  Withdrawal = 'Withdrawal',
  All = 'All',
}

export enum TransferType {
  BankTransfer = 'BankTransfer',
  WalletTransfer = 'WalletTransfer',
}

export interface ITransaction {
  id: string
  amount: number
  totalAmount: number
  currency: string
  createdAt: string
  status?: string
  type: TransactionType
  transferType: TransferType
}

export interface ITransactionData {
  id: string
  type: string
  status: string
  createdAt: string
  amount: number
  currency: string
  isFxTransaction: boolean
  fromAmount: number
  baseCurrency?: string
}

export enum CurrencyTransferType {
  LocalCurrency = 'LocalCurrency',
  USCurrency = 'USCurrency',
}

export enum TransactionDetailSteps {
  Information,
  FundTransfer,
  ProofOfPayment,
  Completed,
}

export interface AccountStatementInvestment {
  amount: number
  currency: string
  apr: number
  interestAmount: number
  payoutFrequency: number
  startDate: string
  daysRemaining: number
  autoRenewal: boolean
}

export interface IStatementDetails {
  currentQuarter: string
  currentPeriod: string
  endDate: string
  fullName: string
  investorAddress: string
  hasNGNWallet: boolean
  walletBalances: WalletBalances
  activeInvestmentBalance: WalletBalances
  interestEarned: WalletBalances
  activeInvestments: AccountStatementInvestment[]
  transactions: ITransaction[] | any[]
}
