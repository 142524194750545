import * as Yup from 'yup'

export const Validation = Yup.object({
  oldPassword: Yup.string().required('Old password is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Confirm password does not match password'
      ),
    }),
})

export const EmailValidation = Yup.object({
  email: Yup.string().required('Email is required'),
})
