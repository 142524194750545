export interface AuthState {
  user: { [key: string]: any }
  loginData: { [key: string]: any }
  loginReturn: { [key: string]: any }
  isAuthenticated: boolean
  isInitialized: boolean
  loading: boolean
  status: string
  twoFaStatus: string
  error: string | undefined
}
export interface ILogin {
  email: string
  password: string
}

export interface ISignup {
  email: string
  password: string
  phoneNumber: string
  confirmPassword: string
  termsAndConditions: boolean
}

export enum ChallengeResponse {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE',
}
