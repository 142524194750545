import Box from '@mui/material/Box'
import './FormComp.scss'

interface Children {
  children: React.ReactNode
}

const FormComp = ({ children }: Children) => {
  return (
    <Box
      sx={{
        display: { xs: 'flex' },
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mx: 'auto',
        gap: 2,
        maxWidth: '400px',
        minHeight: 300,
        background: '#FAFCFF',
        boxSizing: 'border-box',
        overflowY: 'scroll',
      }}
      className="Box"
    >
      {children}
    </Box>
  )
}

export default FormComp
